import React from "react";
import "./FormikSelect.css";
import Select from "react-select";
import { useFormikContext, useField } from "formik";

const FormikSelect = ({ options, label, ...props }) => {
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const [field, meta] = useField(props);

  /**
   * Will manually set the value belong to the name props in the Formik form using setField
   */
  function handleOptionChange(selection) {
    setFieldValue(props.name, selection);
  }

  /**
   * Manually updated the touched property for the field in Formik
   */
  function updateBlur() {
    setFieldTouched(props.name, true);
  }

  return (
    <>
      <Select
        options={options}
        {...field}
        {...props}
        onBlur={updateBlur}
        onChange={handleOptionChange}
        className={"react-select-container"}
        classNamePrefix={"react-select"}
        placeholder=""
        isDisabled={props.disabled}
        theme={theme => ({
          ...theme,
          colors: {
            ...theme.colors,
            // primary25: "#374e22",
            primary: "#5F5F5F",
          },
        })}
      />
      {meta.touched && meta.error ? <span className="custom-input-error">{meta.error.value}</span> : null}
    </>
  );
};

export default FormikSelect;
