const API_URL = "https://api.greatoakvfa.com/";

export const getAuthData = () => {
  const user = "dWb4PfaD7niHUVFuiMSL";
  const password = "tqnzJC6unRv0Lf5T8291";
  return new Buffer(user + ":" + password).toString("base64");
};

export const getStdOptions = () => {
  let token = localStorage.getItem("oak_token");
  return {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token
    },
  };
};

export const deleteStdOptions = () => {
  let token = localStorage.getItem("oak_token");
  return {
    method: "DELETE",
    headers: {
      Authorization: "Bearer " + token,
      'Content-Type': 'application/json'
    },
  };
};

export const postStdOptions = (data) => {
  let token = localStorage.getItem("oak_token");
  return {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
      'Content-Type': 'application/json'
    },
    body: data,
  };
};

export const postFileOptions = (data) => {
  let token = localStorage.getItem("oak_token");
  return {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
    },
    body: data,
  };
};

const authenticate = async firstTime => {
  let token = localStorage.getItem("oak_token");
  const data = new URLSearchParams({
    token,
  });
  const options = {
    method: "POST",
    headers: {
      Authorization: "Basic " + getAuthData(),
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: data,
  };
  return fetchBasic("oauth/check_token", options, firstTime).then(response => {
    return response;
  });
};

const refreshToken = async () => {
  let refToken = localStorage.getItem("oak_ref");
  const data = new URLSearchParams({
    grant_type: "refresh_token",
    refresh_token: refToken,
  });
  const options = {
    method: "POST",
    headers: {
      Authorization: "Basic " + getAuthData(),
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: data,
  };
  return fetchBasic("oauth/token", options, true).then(response => {
    return response;
  });
};

export const auth = {
  async authenticate() {
    const authenticated = await authenticate(true)
      .then(response => {
        if (response.authorities && response.authorities.includes("WEB")) {
          return response;
        }

        if (response.tokenRefreshed) {
          return authenticate(true).then(response => {
            if (response.authorities && response.authorities.includes("WEB")) {
              return response;
            } else {
              return false;
            }
          });
        }
        return false;
      })
      .catch(() => false);
    return authenticated;
  },
  async refresh() {
    const authenticated = await refreshToken()
      .then(response => {
        if (response.access_token && response.authorities.includes("WEB")) {
          localStorage.setItem("oak_token", response.access_token);
          return true;
        }
        return false;
      })
      .catch(() => false);
    return authenticated;
  },
};

class UnauthorizedError extends Error {
  constructor(response) {
    super(response);
    this.name = "UnauthorizedError";
  }
}
class StdError extends Error {
  constructor(response) {
    super(response);
    this.status = response.status;
    this.error = response.error;
    this.message = response.message ? response.message : response.error_description;
  }
}

export async function noJsonFetch(url, options, firstFetch) {
  const res = await fetch(API_URL + url, options);
  if (res.status === 500) {
    throw new StdError({
      message: "Internal server error"
    });
  }
  if (res.status === 400) {
    throw new StdError({
      message: "Bad request error"
    });
  }
  if (res.status === 401) {
    throw new StdError({
      message: "Unauthorized"
    });
  }
  if (res.status === 403) {
    throw new StdError({
      message: "Forbidden error"
    });
  }
  if (res.status === 404) {
    throw new StdError({
      message: "Not found error"
    });
  }
  return res;
}

async function fetchBasic(url, options, firstFetch) {
  const res = await fetch(API_URL + url, options);
  return res.json().then(firstResponse => {
    if (firstResponse.error === "invalid_token" && firstFetch) {
      return auth.refresh().then(response => {
        if (!response) {
          throw new UnauthorizedError(response);
        }
        return { tokenRefreshed: true };
      });
    }
    if (firstResponse.error === "invalid_token") {
      throw new UnauthorizedError(firstResponse);
    }
    
    if (firstResponse.error) {
      throw new StdError(firstResponse);
    }
    return firstResponse;
  });
}

export function saveDraft(id, clientName, form, saveDraft) {
  const json = JSON.stringify({
    id,
    clientName,
    form: JSON.stringify(form),
    send: saveDraft ? true : false
  });
  const options = postStdOptions(json);
  return noJsonFetch("draft/save", options, true)
  .then(response => {
    if (response.tokenRefreshed) {
      return noJsonFetch("draft/save", postStdOptions(json), false)
        .then(response => {
          return response;
        })
        .catch(err => {
          throw new Error(err);
        });
    } else {
      return response;
    }
  })
  .catch(err => {
    throw new Error(err);
  });
}

export function saveDraftFile(draftId, file) {
  var formData = new FormData();
  formData.append("file", file); 
  const options = postFileOptions(formData);
  return fetchBasic(`file/draft/save?draftId=${draftId}`, options, true)
  .then(response => {
    if (response.tokenRefreshed) {
      return fetchBasic(`file/draft/save?draftId=${draftId}`, postFileOptions(formData), false)
        .then(response => {
          return response;
        })
        .catch(err => {
          throw new Error(err);
        });
    } else {
      return response;
    }
  })
  .catch(err => {
    throw new Error(err);
  });
}

export function saveStsFile(file, draftId) {
  var formData = new FormData();
  formData.append("file", file); 
  const options = postFileOptions(formData);
  return fetchBasic(`file/draft/termSheet/save?draftId=${draftId}`, options, true)
  .then(response => {
    if (response.tokenRefreshed) {
      return fetchBasic(`file/draft/termSheet/save?draftId=${draftId}`, postFileOptions(formData), false)
        .then(response => {
          return response;
        })
        .catch(err => {
          throw new Error(err);
        });
    } else {
      return response;
    }
  })
  .catch(err => {
    throw new Error(err);
  });
}

export function saveFormScreenshot(file, draftId) {
  var formData = new FormData();
  formData.append("file", file); 
  const options = postFileOptions(formData);
  return fetchBasic(`file/draft/referralFrom/save?draftId=${draftId}`, options, true)
  .then(response => {
    if (response.tokenRefreshed) {
      return fetchBasic(`file/draft/referralFrom/save?draftId=${draftId}`, postFileOptions(formData), false)
        .then(response => {
          return response;
        })
        .catch(err => {
          throw new Error(err);
        });
    } else {
      return response;
    }
  })
  .catch(err => {
    throw new Error(err);
  });
}

export function deleteDraftFile(id) {
  const options = deleteStdOptions();
  return noJsonFetch(`file/draft/delete?uuid=${id}`, options, true)
  .then(response => {
    if (response.tokenRefreshed) {
      return noJsonFetch(`file/draft/delete?uuid=${id}`, deleteStdOptions(), false)
        .then(response => {
          return response;
        })
        .catch(err => {
          return err;
        });
    } else {
      return response;
    }
  })
  .catch(err => {
    throw new StdError(err);
  });
}

export const getDraftData = (draftId) => {
  const options = getStdOptions();
  return fetchBasic(`/draft/details?id=${draftId}`, options, true)
  .then(response => {
    if (response.tokenRefreshed) {
      return fetchBasic(`/draft/details?id=${draftId}`, getStdOptions(), false)
        .then(response => {
          return response;
        })
        .catch(err => {
          return err;
        });
    } else {
      return response;
    }
  })
  .catch(err => {
    throw new StdError(err);
  });
}

export const getDraftPresentationData = (code) => {
  const options = { method: "GET" };
  return fetchBasic(`cms/draft?code=${code}`, options, true)
  .then(response => {
    return response;
  })
  .catch(err => {
    throw new StdError(err);
  });
}


export default fetchBasic;
