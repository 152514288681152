import React from "react";
import "./FormikCheckbox.css";
import { useFormikContext } from "formik";

const FormikCheckbox = props => {
  const { setFieldValue } = useFormikContext();
  let checked;
  if (!props.oneOptionCheckbox) {
    checked = props.checked.includes(props.value);
  } else {
    checked = props.checked;
  }
	
	const updateChecked = (e) => {
		let newChecked;
		if (!checked){
			newChecked = [...props.checked, e.target.value];
		} 
		else {
			newChecked = props.checked.filter(element => {
				return element !== props.value;
			});
		}
		setFieldValue(props.name, newChecked)
  }
  
	const updateCheckedSting = (e) => {
		let newChecked;
		if (!checked){
			newChecked = true;
		} 
		else {
			newChecked = "";
		}
		setFieldValue(props.name, newChecked)
	}

  return (
    <label className="checkboxContainer">
      <span className={`checkboxLabel ${checked && "checkboxChecked"}`}></span>
      <span dangerouslySetInnerHTML={{
        __html: props.label,
      }}></span>
      {props.oneOptionCheckbox ?
        <input
        type="checkbox"
        name={props.name}
        value={props.value}
        checked={checked}
        disabled={props.disabled}
        onChange={(e) => updateCheckedSting(e)}
      /> : <input
        type="checkbox"
        name={props.name}
        value={props.value}
        checked={checked}
        disabled={props.disabled}
        onChange={(e) => updateChecked(e)}
      />}
    </label>
  );
};

export default FormikCheckbox;
