import React, { useEffect, useState, useContext } from "react";
import UserContext from "../../UserContext";
import NewReferralBoxDetails from "../../components/NewReferralBox/NewReferralBoxDetails";
import fetchBasic, { getStdOptions } from "../../utils/fetch";
import { useParams } from "react-router-dom";
import "./NewReferral.css";

const NewReferralDetails = () => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const [data, setData] = useState([]);
  const user = useContext(UserContext);
  let { id } = useParams();

  useEffect(() => {
    const options = getStdOptions();
    fetchBasic(`service/employees?serviceId=${id}`, options, true)
      .then(response => {
        if (response.tokenRefreshed) {
          return fetchBasic(`service/employees?serviceId=${id}`, getStdOptions(), false)
            .then(response => {
              setDataLoaded(true);
              setData(response);
            })
            .catch(err => {
              return err;
            });
        } else {
          setDataLoaded(true);
          setData(response);
        }
      })
      .catch(err => {
        user.setRequestError(err.message);
        return err;
      });
  }, [id]);

  return (
    <>
      <a target="_blank" href="https://api.greatoakvfa.com/banner" rel="noopener noreferrer"><img className="adBaner" src="https://api.greatoakvfa.com/static/banner.png" alt=""/></a> 
      <div className="mainReferralContainer mainReferralDetailsContainer">
        <p>New referral</p>
        {data.title && <h1>/ {data.title}</h1>}
        <section className="newReferralColContainer">
          {data.employees && !data.employees.length && dataLoaded && <h4 style={{width: "100%"}}>No service providers</h4>}
          <div className="referralCol">
            {data.employees &&
              data.employees.map((item, index) => {
                if (index % 2 === 0) {
                  return <NewReferralBoxDetails item={item} key={item.id} parentId={id} />;
                }
                return null;
              })}
          </div>
          <div className="referralCol">
            {data.employees &&
              data.employees.map((item, index) => {
                if (index % 2 === 1) {
                  return <NewReferralBoxDetails item={item} key={item.id} parentId={id} />;
                }
                return null;
              })}
          </div>
        </section>
      </div>
    </>
  );
};

export default NewReferralDetails;
