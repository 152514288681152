import React, { useEffect, useState, useContext } from "react";
import { MDBTooltip, MDBBtn } from "mdbreact";
import fetchBasic, { noJsonFetch, getStdOptions } from "../../utils/fetch";
import { numberWithCommas, dashboardAmountToNumber } from "../../utils/utils";
import UserContext from "../../UserContext";
import fileIcon from "../../assets/file.png";
import upTick from "../../assets/upTick.png";
import download from 'downloadjs'
import "./Dashboard.css";

const Dashboard = () => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const [data, setData] = useState([]);
  const user = useContext(UserContext);
  const [showAdditionalInfo, setShowAdditionalInfo] = useState(null);
  useEffect(() => {
    const options = getStdOptions();
    fetchBasic("referral/list", options, true)
      .then(response => {
        if (response.tokenRefreshed) {
          return fetchBasic("referral/list", getStdOptions(), false)
            .then(response => {
              setDataLoaded(true);
              setData(response);
            })
            .catch(err => {
              return err;
            });
        } else {
          setDataLoaded(true);
          setData(response);
        }
      })
      .catch(err => {
        user.setRequestError(err.message);
        return err;
      });
  }, []);

  useEffect(() => {
    if (!("draftCount" in user) || !("referralCount" in user)) {
      const options = getStdOptions();
      fetchBasic("user/items", options, true)
        .then(response => {
          user.setNewDraftReferralCount(response.draftCount, response.referralCount);
        })
        .catch(err => {
          return err;
        });
    }
  }, []);

  const downloadFile = (id, name) => {
    const options = getStdOptions();
    noJsonFetch(`file/referral?uuid=${id}`, options, true)
      .then(response => {
        if (response.tokenRefreshed) {
          return noJsonFetch(`file/referral?uuid=${id}`, getStdOptions(), false)
            .then(response => {
              return response
            })
            .catch(err => {
              return err;
            });
        } else {
          return response;
        }
      }).then(response => {
        const reader = response.body.getReader();
        const stream = new ReadableStream({
          start(controller) {
            // The following function handles each data chunk
            function push() {
              // "done" is a Boolean and value a "Uint8Array"
              reader.read().then(({ done, value }) => {
                // Is there no more data to read?
                if (done) {
                  // Tell the browser that we have finished sending data
                  controller.close();
                  return;
                }

                // Get the data and send it to the browser via the controller
                controller.enqueue(value);
                push();
              });
            };

            push();
          }
        })
        return new Response(stream, { headers: { "Content-Type": "application/pdf" } });
      }).then(response => {
        return response.blob()
      }).then(blob => {
        download(blob, name);
      })
      .catch(err => {
        user.setRequestError(err.message);
        return err;
      });
  }

  if (!data.length && dataLoaded) {
    return (
      <div className="dashboardReferralContainer">
        <a target="_blank" href="https://api.greatoakvfa.com/banner" rel="noopener noreferrer"><img className="adBaner" src="https://api.greatoakvfa.com/static/banner.png" alt="" /></a>
        <h1>File Status</h1>
        <section style={{ marginTop: "25px" }}>{!data.length && dataLoaded && <h4>No files</h4>}</section>
      </div>
    );
  }

  if (!data.length) {
    return (
      <div className="dashboardReferralContainer">
        <h1>File Status</h1>
      </div>
    );
  }
  let newItems = data.filter(item => item.isNew);

  const completedStatus = (dotName, status) => {
    if (status === "Completed") {
      return "completed";
    }
    const collectingCompleted = ["booking"];
    const typingCompleted = ["booking", "collecting"];

    switch (status) {
      case 'Booking':
        if (dotName === "booking") {
          return "inProgress";
        }
        return "pending";
      case 'Collecting':
        if (dotName === "collecting") {
          return "inProgress";
        }
        return collectingCompleted.includes(dotName) ? "completed" : "pending"
      case 'Typing':
        if (dotName === "typing") {
          return "inProgress";
        }
        return typingCompleted.includes(dotName) ? "completed" : "pending"
      default:
        return "pending"
    }
  }
  return (
    <>
      <a target="_blank" href="https://api.greatoakvfa.com/banner" rel="noopener noreferrer"><img className="adBaner" src="https://api.greatoakvfa.com/static/banner.png" alt="" /></a>
      <div className="dashboardReferralContainer">
        <h1 className="dashboardH1">
          <span>File Status</span>
          {newItems.length ? <span className="newStatuses">{newItems.length} New {newItems.length > 1 ? "Statuses" : "Status"}</span> : null}
        </h1>
        <section className="dashboardMarginContainer">
          {!data.length && dataLoaded && <h4>No files</h4>}
          <div className="dashboardRow dashboardRowContainer dashboardHead">
            <div className="dashboardName">Client</div>
            <div className="dashboardStdWidth">
              Service{" "}
              <MDBTooltip placement="top">
                <MDBBtn className="tooltipStd dashboardTooltip">i</MDBBtn>
                <div>This is the service you have requested for this client.</div>
              </MDBTooltip>
            </div>
            <div className="dashboardStdWidth">
              New Referral Review
              <MDBTooltip placement="top">
                <MDBBtn className="tooltipStd dashboardTooltip">i</MDBBtn>
                <div>Our staff is currently processing this new referral in order to start actively working on the file/service. In addition, a case manager may be assigned as a point of contact.</div>
              </MDBTooltip>
            </div>
            <div className="dashboardStdWidth">
              Initial
              <br />
              Conultation
              <MDBTooltip placement="top">
                <MDBBtn className="tooltipStd dashboardTooltip">i</MDBBtn>
                <div>The staff is in the process of completing an in-person or phone/remote consultation with the client.</div>
              </MDBTooltip>
            </div>
            <div className="dashboardStdWidth">
              Document Collection
              <MDBTooltip placement="top">
                <MDBBtn className="tooltipStd dashboardTooltip">i</MDBBtn>
                <div>The staff is actively collecting, reviewing and analyzing documentation in order to complete the requested service.  This stage is highly contingent on client cooperation.</div>
              </MDBTooltip>
            </div>
            <div className="dashboardStdWidth">
              Report
              <br />
              Completion
              <MDBTooltip placement="top">
                <MDBBtn className="tooltipStd dashboardTooltip">i</MDBBtn>
                <div>The staff and/our consultant(s) are in the process of generating a report for the requested service including quality assurance.</div>
              </MDBTooltip>
            </div>
            <div className="dashboardStdWidth">
              Final
              <br />
              Report & Invoice
              <MDBTooltip placement="top">
                <MDBBtn className="tooltipStd dashboardTooltip">i</MDBBtn>
                <div>Please find attached the completed report, corresponding invoice and supporting documentation if/when applicable for the requested service.</div>
              </MDBTooltip>
            </div>
            <div className="dashboardAmountDue">
              Amount
              <br />
              Due
            </div>
            <div className="dashboardButtonMore"></div>
          </div>
          {data.map((item, index) => {
            const newReferral = item.isNew && item.status === "New Referral Review";
            const newConsultation = item.isNew && item.status === "Booking";
            const newCollection = item.isNew && item.status === "Collecting";
            const newCompletion = item.isNew && item.status === "Typing";
            const newFinal = item.isNew && item.status === "Completed";
            return (
              <div key={index} className="stdDashboardRowContainer">
                <div className="dashboardRow dashboardStdRow" onClick={() => setShowAdditionalInfo(index)}>
                  <div className="dashboardMobileLabel">Client</div>
                  <div className="dashboardName">{item.isNew ? <span className="newDot"></span> : null}{item.clientFullName}</div>
                  <div className="dashboardMobileLabel">Service</div>
                  <div className="dashboardStdWidth">{item.service}</div>
                  <div className="dashboardMobileLabel">New Referral Review</div>
                  <div className="dashboardStdWidth dashboardProgressContainer completedContainer">
                    <span className="progressIcon">
                      {newReferral && <span className="newStatuses newStatusesSmall">New</span>}
                    </span>
                  </div>
                  <div className="dashboardMobileLabel">Initial Conultation</div>
                  <div className={`dashboardStdWidth dashboardProgressContainer dashboardGradientContainer ${completedStatus("booking", item.status)}Container`}>
                    <span className={`progressIcon ${completedStatus("booking", item.status)}`}>
                      {newConsultation && <span className="newStatuses newStatusesSmall">New</span>}
                    </span>
                  </div>
                  <div className="dashboardMobileLabel">Document Collection</div>
                  <div className={`dashboardStdWidth dashboardProgressContainer dashboardGradientContainer ${completedStatus("collecting", item.status)}Container`}>
                    <span className={`progressIcon ${completedStatus("collecting", item.status)}`}>
                      {newCollection && <span className="newStatuses newStatusesSmall">New</span>}
                    </span>
                  </div>
                  <div className="dashboardMobileLabel">Report Completion</div>
                  <div className={`dashboardStdWidth dashboardProgressContainer dashboardGradientContainer ${completedStatus("typing", item.status)}Container`}>
                    <span className={`progressIcon ${completedStatus("typing", item.status)}`}>
                      {newCompletion && <span className="newStatuses newStatusesSmall">New</span>}
                    </span>
                  </div>
                  <div className="dashboardMobileLabel">Final Report & Invoice</div>
                  <div className={`dashboardStdWidth dashboardProgressContainer dashboardGradientContainer ${completedStatus("completed", item.status)}Container`}>
                    <span className={`progressIcon ${completedStatus("completed", item.status)}`}>
                      {newFinal && <span className="newStatuses newStatusesSmall">New</span>}
                    </span>
                  </div>
                  <div className="dashboardMobileLabel">Amount Due</div>
                  <div className="dashboardAmountDue">${numberWithCommas(dashboardAmountToNumber(item.amountDue))}</div>
                  <div className="dashboardMobileLabel"></div>
                  <div className="dashboardButtonMore"><span className="dashboardShowDetailsButton btn btn-std">Show Details</span></div>
                </div>
                <div className={`dashboardAdditionalInfo ${showAdditionalInfo === index && "showAdditionalInfo"}`}>
                  <div className="dashboardAdditionalStd">
                    <p>Case Manager</p>
                    <p className="dashboardAdditionalValue">{item.caseManagerFullName}</p>
                    <p>Case Manager Email</p>
                    <p className="dashboardAdditionalValue underline">
                      <a href={`mailto:${item.caseManagerEmail}`}>{item.caseManagerEmail}</a></p>
                    <p>Case Manager Status</p>
                    <p className="dashboardAdditionalValue">{item.status}</p>
                  </div>
                  <div className="dashboardAdditionalStd">
                    <p>Invoice #</p>
                    <p className="dashboardAdditionalValue">{item.invoice}</p>
                    <p>Invoice Amount</p>
                    <p className="dashboardAdditionalValue">${numberWithCommas(dashboardAmountToNumber(item.invoiceAmount))}</p>
                  </div>
                  <div className="dashboardFilesContainer">
                    {item.files.length ? <p>Files</p> : null}
                    <div>
                      {item.files.map(file => {
                        return (
                          <span onClick={() => downloadFile(file.uuid, file.name)} key={file.uuid} className="dashboardFile">
                            <img src={fileIcon} className="fileIcon" alt="Download File Icon" />
                            <span>{file.name}</span>
                          </span>
                        );
                      })}
                    </div>
                  </div>
                  <button className="dahsboardHideDetailsContainer" onClick={() => setShowAdditionalInfo(null)}>
                    Hide <img src={upTick} className="upTickIcon" alt="upTick" />
                  </button>
                </div>
              </div>
            );
          })}
          <div className="dashboardLegend">
            <div>
              <span className="progressIcon"></span> <span>completed</span>
            </div>
            <div>
              <span className="progressIcon inProgress"></span> <span>in progress</span>
            </div>
            <div>
              <span className="progressIcon pending"></span> <span>pending</span>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Dashboard;
