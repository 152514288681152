import React from "react";
import "./Authentication.css";

const Page404 = () => {
  return (
    <div className="mainAuthenticationContainer">
			<div className="pageNotFound">
				Page not found
			</div>
    </div>
  );
}

export default Page404;
