import React from "react";
import "./Modal.css";
import logo from "../../assets/logo.png";
import { MDBModal, MDBModalBody } from "mdbreact";

const Modal = props => {
  return (
    <MDBModal isOpen={props.visible} size="lg" centered>
      <MDBModalBody>
        <div className="stdModalBody">
          <div style={{ borderBottom: "1px solid #f4f4f5", textAlign: "center" }}>
            <img src={logo} className="authenticationLogo" alt="Great Oak logo" />
          </div>
          <div>
            <div className="serviceModalContainer">
              <h3>GREAT OAK LIVE TERMS OF SERVICE</h3>
              <p>THESE TERMS OF SERVICE (THESE “TERMS”) CREATE A BINDING CONTRACT BETWEEN YOU (HEREINAFTER REFERRED TO AS THE “USER”), AND GREAT OAK VFA INC. (HEREINAFTER REFERRED TO AS “GREAT OAK”) AND GOVERNS YOUR ACCESS TO AND USE OF <span className="boldUnderline">GREAT OAK LIVE</span> (THE “AGREEMENT”). PLEASE READ THIS AGREEMENT CAREFULLY. YOU UNDERSTAND AND ACKNOWLEDGE THAT IN ACCEPTING TO BE BOUND BY THESE TERMS, YOU ARE DEEMED AS AN AUTHORIZED REPRESENTATIVE OF YOUR EMPLOYER OR ENTITY (AS DEFINED BELOW) IF YOU ARE USING YOUR EMPLOYER OR AN ENTITY’S E-MAIL ADDRESS TO REGISTER TO GREAT OAK LIVE OR IF YOU ARE AN ADMINISTRATOR OR AGENT FOR AN EMPLOYER OR ENTITY. TO CONFIRM YOUR UNDERSTANDING AND ACCEPTANCE OF THIS AGREEMENT, PLEASE SELECT “AGREE” IN THE SPACE PROVIDED TO CONTINUE.</p>
              <p>BY AGREEING TO THESE TERMS YOU UNDERSTAND THAT YOU ARE REQUESTING EXPERT SERVICES WHICH HAVE BEEN SELECTED BY YOU ABOVE. PLEASE ENSURE THAT YOU HAVE REQUESTED THE CORRECT EXPERT SERVICES BEFORE YOU CONTINUE. BY AGREEING TO THESE TERMS YOU ACKNOWLEDGE THAT YOU HAVE REVIEWED AND UNDERSTAND THE EXPERT SERVICE YOU ARE REQUESTING, INCLUDING THE ASSOCIATED COST OF THE EXPERT SERVICE. YOU ACKNOWLEDGE THAT THE COST OF THE EXPERT SERVICE IS A DISBURSEMENT COST WHICH THE CLIENT IS RESPONSIBLE TO PAY. YOU AGREE TO PROTECT THE COST OF THE EXPERT SERVICE AGAINST THE CLIENT’S ACCOUNT.</p>
              <h4>DEFINITIONS</h4>
              <p>1.1 In this Agreement,</p>
              <p>“Account” means the formal business arrangement providing for regular dealings and services through Great Oak Live, and is associated and linked to a User and User Profile; 
              </p>
              <p>“Client” means any individual or entity referred through Great Oak Live by the User for the provision of an Expert Service. For the purpose of this Agreement, a Client may be used in reference to a client of Great Oak or other Expert Service Provider, or the referring User;
              </p>
              <p>“Client Information” means any data, file attachments, text, images, reports, personal information, or any other content, that is uploaded or submitted, transmitted or otherwise made available by the User or the Client to Great Oak; 
              </p>
              <p>“Employer or entity” means any individual, corporation, partnership or other business entity that the User is employed by, in partnership, affiliation or association with, or otherwise represents or acts on behalf of for the purpose of accessing and using Great Oak Live; </p>
              <p>“Engagement” means a request or referral of a client to Great Oak, or other Expert Provider for the purpose of providing an Expert Service, which request or referral has been processed and accepted and the Expert Service Provider has been duly engaged, retained, or otherwise hired by the Client and/or the User on behalf of the Client, to complete the requested Expert Service; 
              </p>
              <p>“Expert Service” means expert report or assessment, as well as client management products and services, and other ancillary products and services which can be obtained through Great Oak Live. Expert Services may include, but are not limited to:
              </p>
              <p>
              (a) Income Replacement Benefit Report,<br></br>
              (b) Loss of Income Report,<br></br>
              (c) Loss of Income Short Report,<br></br>
              (d) Present Value Report,<br></br>
              (e) Pension and Benefit Loss Report,<br></br>
              (f) Vocational Assessment,<br></br>
              (g) Labour Market Analysis,<br></br>
              (h) Case Management Services,<br></br>
              (i) Translation Services,<br></br>
              (j) Updated, modified or amended expert reports, and<br></br>
              (k) Any other product or service which may be obtained using the Service;<br></br>
              </p>
              <p>“Great Oak” means Great Oak VFA Inc., its affiliates, principals, agents, subsidiaries, successors and assigns, as the case may be, and does not include any third-party individual or entity that may provide products or services through Great Oak Live;
              </p>
              <p>“Service” means the access to and use of Great Oak Live;
              </p>
              <p>“Service Provider” means a qualified forensic expert, or other qualified or designated professional, or an entity through which forensic expert or professional services are provided, and include individuals and entities employed by, affiliated or associated with Great Oak, and third-party individuals and entities that provide Expert Services through Great Oak Live;
              </p>
              <p> “User” means the individual or entity with a registered Account which provides access and use of Great Oak Live;
              </p>
              <p>“User Profile” means the personal information used to identify the User associated or linked to a registered Account.
              </p>
              <h4>2. SERVICE & USE</h4>
              <p>2.1 <u>Great Oak Live</u>. Great Oak Live is an online case management and service request tool that provides the User access to qualified experts and other designated professionals, and allows the User to request, track, manage, store and receive Expert Services. The User may use the Service to refer Clients to an Expert Service Provider for the completion of an Expert Service and track the progress of an Engagement, as well as upload documents and track payments.</p>
              <p>2.2 Services.</p>
              <p>2.2 <u>Use Restrictions</u>. Except as expressly permitted in these Terms, the User may not, and shall not (i) allow any other individual or entity access to its Account; or (ii) access any other Accounts which are not associated or linked to its specific User Profile.</p>
              <p>2.3 <u>Authorization & Direction</u>. The User represents and warrants that upon referring a Client to Great Oak or other Service Provider for the completion of an Expert Service, the User consents and hereby authorizes and directs Great Oak, the Service Provider and their agents to contact the Client, request, receive, collect and store Client Information, and use and disclose Client Information for the purpose of completing an Engagement. </p>
              <p>2.4 <u>Service and Provider Availability</u>. The User acknowledges and understands that the Service Providers requested by the User will be subject to their availability. Great Oak will attempt to provide the Expert Services and the Services Providers requested by the User, but cannot guarantee their availability upon request. In the event that an Expert Service or Service Provider is unavailable upon request by the User, the User shall be notified and if available, another Service Provider will be made provided.</p>
              <p>2.5 <u>Service Completion</u>. Great Oak works in cooperation with various parties to facilitate the completion of Expert Services by Great Oak and/or other third-party Service Providers. Once an Engagement commences, the provision and completion of Expert Services is contingent on the cooperation of all relevant parties. The User acknowledges and understands that an Engagement may not be completed, through no fault of Great Oak, if any of the following occur:</p>
              <p>
              (a) Great Oak receives false, inaccurate or misleading information, including Client Information, which thereafter is not corrected or otherwise remedied;<br></br>
              (b) the User, the Client, or other third-party refuses or otherwise fails to comply with requests for information; <br></br>
              (c) the Client refuses or otherwise fails to attend at a scheduled consultation, in-take, or other necessary appointment with a Service Provider or other individual or entity as may be required;<br></br>
              (d) the User, the Client, or other third-party refuses or otherwise fails to respond to correspondence in a timely manner; <br></br>
              (e) the User, the Client, or other third-party (as applicable) refuses or otherwise fails to provide instruction or direction as may be requested from time to time; or<br></br>
              (f) any other event, conduct, action or inaction occurs which impedes or prevents a Service Provider from completing an Engagement.
              </p>
              <p>2.6 <u>Estimated Engagement Time</u>. Where Great Oak agrees to provide an estimated time period for the completion of an Engagement, such estimate is not in any way a guarantee that an Engagement will be completed by a specific date or within a certain time period. Great Oak reserves the right to update the estimated time for completing an Engagement and may be required to do so if the Engagement is impeded or delayed for any of the following reasons:</p>
              <p>
              (a) Great Oak receives false, inaccurate or misleading information, including Client Information, which thereafter is not corrected or otherwise remedied;<br></br>
              (b) the User, the Client, or other third-party refuses or otherwise fails to comply with requests for information;<br></br>
              (c) the Client refuses or otherwise fails to attend at a scheduled consultation, in-take, or other necessary appointment with a Service Provider or other individual or entity as may be required;<br></br>
              (d) the User, the Client, or other third-party refuses or otherwise fails to respond to correspondence in a timely manner; <br></br>
              (e) the User, the Client, or other third-party (as applicable) refuses or otherwise fails to provide instruction or direction as may be requested from time to time; or<br></br>
              (f) any other event, conduct, action or inaction occurs which impedes or prevents a Service Provider from completing an Engagement.<br></br>
              </p>
              <p>
              2.7 <u>Waiver of Liability</u>. The User acknowledges and agrees that Great Oak, and any Expert Service Provider obtained through the Service is not and shall not be liable to the User, the Client or any other third-party for any damages, whatsoever, due to the inability to complete an Engagement or adhere to an estimated time for the completion of an Engagement.
              </p>
              <h4>3. DATA & INFORMATION</h4>
              <p>3.1 <u>Client Information</u>. Prior to, upon, during or after an Engagement for Expert Services, the User or the Client may upload or submit, transmit or otherwise make available, to or through the Service, or by any other available means that does not require use of the Service. The User acknowledges and understands that in order to complete an Engagement, Great Oak must request, collect, use and disclose information and data, including Client Information. </p>
              <p>3.2 <u>Authorization</u>. The User represents and warrants that: (i) it has or has obtained all rights, licenses, consents, permissions, power and/or authority necessary to upload, submit, transmit or otherwise disclose or make available to Great Oak, Client Information in its possession and control; and (ii) the Client Information submitted or transmitted to Great Oak does not and shall not (a) infringe or violate any proprietary, privacy or data protection rights of any Client or third-party; (b) violate any applicable provincial or federal laws and regulations, including those related to data privacy and data transfer; and (c) be false, misleading, inaccurate, the User knowing it to be such. Other than Great Oak’s data and information protection and retention obligations, Great Oak assumes no responsibility or liability for Client Data submitted or transmitted to or through the Service. </p>
              <p>3.3 <u>Information Disclosure</u>. The User acknowledges and understands that Great Oak or other Service Provider may be required to disclose information, data and Client Information to relevant parties during the course of an Engagement. Great Oak shall only disclose information, data and Client information in accordance with its privacy policy and procedures and for the purpose of completing an Engagement, providing Expert Services, or if mandated by law</p>
              <p>3.4 <u>False and Misleading Information</u>. Except in accordance with any professional standards and practices, Great Oak, its employees, associates, and affiliates are under no obligation to verify the truth, efficacy, accuracy, correctness or completeness of Client Information or other information and data provided by the User or the Client. The User warrants and represents that, to the best of their knowledge, all information and data, including Client Information, provided by the User is true, accurate, correct and does not mislead or misrepresent facts. Great Oak reserves the right to withdraw from an Engagement for Expert Services should the User or the Client provide false, inaccurate or misleading information and data, including Client Information and thereafter fails to correct, verify or otherwise remedy that information, data, or Client Information.</p>
              <h4>4. INTELLECTUAL PROPERTY</h4>
              <p>4.1 <u>Great Oak Intellectual Property</u>. The Service, inclusive of software, application programming interface, design, text, informational text, photographs, audio-visual media and other graphic materials, including names, logos and trademarks (“Great Oak Intellectual Property”) are the property of Great Oak and its licensors and assigns as the case may be, and is protected by applicable provincial and federal copyright and intellectual property laws in Canada. Great Oak retains all rights, titles and interests, including all intellectual property rights, in and to the Great Oak Intellectual Property.</p>
              <p>4.2 <u>Expert Reports and Assessments</u>. The User warrants and represents that they shall not in any way change, amend or modify any expert report or assessment, or any other work product produced during an Engagement or the provision of an Expert Service. </p>
              <h4>5. INVOICING AND PAYMENTS</h4>
              <p>5.1 <u>Invoicing</u>. Great Oak invoices for Expert Services in accordance with its standard invoicing and payment practices. All invoices rendered by Great Oak are due within thirty (30) days from the date of invoice, unless otherwise advised by Great Oak. Interest may accrue on all overdue amounts at a rate advised by Great Oak from time to time. Once an invoice is generated, it will be available for download through the Service and the User may track payment records and balances through the Service.</p>
              <p>5.2 <u>Assignment</u>. Great Oak may assign or transfer any and all of Great Oak’s rights to debts, or any other chose in action respecting outstanding accounts for Expert Services. In the event that Great Oak assigns or transfers partially or in whole any of its rights to a debt or chose in action, Great Oak shall give express notice in writing to the debtor, trustee, fiduciary, or other person from whom Great Oak would have been entitled to receive or claim such debt or chose in action. The assignee shall retain all rights and remedies at law and in equity.</p>
              <h4>6. OTHER TERMS</h4>
              <p>6.1 <u>User Authentication</u>. The User acknowledges that Great Oak has the authority to cause the User’s Account to be re-authenticated from time to time. The User acknowledges that re-authentication is required to ensure that the User remains employed, partnered, affiliated or associated with their employer or entity of which the User is the authorized representative. The User acknowledges that in the event they fail to re-authenticate their Account cease employment, partnership, affiliation or association with their employer or entity, Great Oak shall suspend and/or deactivate the User’s Account immediately, and the User will no longer have access to the Account.</p>
              <p>6.2 <u>Client Relationship</u>. The User warrants that if there is a breakdown in the relationship between the User and the Client, the User shall notify Great Oak as soon as practicable. If Great Oak receives notice of a breakdown in the relationship between the User and the Client, Great Oak may invoice for services rendered up to the notice date.</p>
              <h4>7. DISCLAIMER</h4>
              <p>7.1 <u>Disclaimer</u>. The User acknowledges and agrees that the use of the Service is at the User’s sole risk. To the extent permitted by law, the access to and use of the Service is provided “as is” and “as available,” with all faults and without warranty of any kind. Great Oak makes no guarantees that the Service will always be secure, error free, or function without disruptions, delays, or imperfections. Great Oak cannot predict when issues related to the Service may arise and therefore, Great Oak’s liability shall be limited to the fullest extent permitted by law, and under no circumstance will Great Oak be liable for any lost profit, revenues, information, or data, special, punitive, exemplary or incidental damages arising out of or related to these Terms or the Service, even if advised with proper notice of the possibility of such damages. </p>
              <p>TO CONFIRM YOUR UNDERSTANDING AND ACCEPTANCE OF THIS AGREEMENT, PLEASE SELECT “AGREE” IN THE SPACE PROVIDED TO CONTINUE.</p>
            </div>
            <div style={{ marginBottom: "16px" }}>
              <button className="btn btn-std btn-negative" onClick={props.close}>
                Cancel
              </button>
              <button className="btn btn-std" onClick={props.agree}>
                Agree
              </button>
            </div>
          </div>
        </div>
      </MDBModalBody>
    </MDBModal>
  );
};

export default Modal;
