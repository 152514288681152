import React, { useEffect, useState, useContext } from "react";
import trash from "../../assets/trash.png";
import fetchBasic, { getStdOptions, deleteStdOptions, noJsonFetch } from "../../utils/fetch";
import { urls } from "../../utils/routes";
import { Redirect } from "react-router-dom";
import UserContext from "../../UserContext";
import Moment from "moment";
import "./DraftReferral.css";

const DraftReferral = () => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const [data, setData] = useState([]);
  const [redirect, setRedirect] = useState("");
  const user = useContext(UserContext);

  useEffect(() => {
    const options = getStdOptions();
    fetchBasic("draft/list", options, true)
      .then(response => {
        if (response.tokenRefreshed) {
          return fetchBasic("draft/list", getStdOptions(), false)
            .then(response => {
              setDataLoaded(true);
              user.setNewDraftCount(response.length);
              setData(response);
            })
            .catch(err => {
              return err;
            });
        } else {
          setDataLoaded(true);
          user.setNewDraftCount(response.length);
          setData(response);
        }
      })
      .catch(err => {
        user.setRequestError(err.message);
        return err;
      });
  }, []);
  const handleDelete = (id) => {
    const options = deleteStdOptions();
    return noJsonFetch(`draft/delete?id=${id}`, options, true)
      .then(response => {
        if (response.tokenRefreshed) {
          return noJsonFetch(`draft/delete?id=${id}`, deleteStdOptions(), false).then(() => {
            const newData = data.filter(item => item.id !== id);
            setData(newData)
            user.updateDraftCount(-1);
          })
            .catch(err => {
              return err;
            });
        } else {
          const newData = data.filter(item => item.id !== id);
          setData(newData)
          user.updateDraftCount(-1);
        }
      })
      .catch(err => {
        user.setRequestError(err.message);
        return err;
      });
  }

  if (redirect) {
    return (
      <Redirect
        to={{
          pathname: `${urls.formContainer}/${redirect.formCode.toLowerCase()}`,
          state: { draftId: redirect.id },
        }}
      />
    )
  }

  if (!data.length && dataLoaded) {
    return (
      <>
        <a target="_blank" href="https://api.greatoakvfa.com/banner" rel="noopener noreferrer"><img className="adBaner" src="https://api.greatoakvfa.com/static/banner.png" alt="" /></a>
        <div className="draftReferralContainer">
          <h1>Drafts referrals</h1>
          <section style={{ marginTop: "25px" }}>
            <h4>No referrals</h4>
          </section>
        </div>
      </>
    )
  }

  return (
    <>
      <a target="_blank" href="https://api.greatoakvfa.com/banner" rel="noopener noreferrer"><img className="adBaner" src="https://api.greatoakvfa.com/static/banner.png" alt="" /></a>
      <div className="draftReferralContainer">
        <h1>Drafts referrals</h1>
        <section className="draftReferralMarginContainer">
          <div className="draftReferralRow draftReferralHead">
            <div className="draftReferralName">Client name</div>
            <div className="draftReferralService">Service</div>
            <div className="draftReferralServiceProvider">
              Service Provider
            </div>
            <div className="draftReferralEditDate">Last edit</div>
            <div className="draftReferralEditButton"></div>
            <div className="draftReferralDelete"></div>
          </div>
          {data.map((item, index) => {
            return (
              <div className="draftReferralRow draftReferralStdRow" key={index}>
                <div className="draftReferralMobileLabel">Client name</div>
                <div className="draftReferralName">{item.client ? item.client : "Name not provided"}</div>
                <div className="draftReferralMobileLabel">Service</div>
                <div className="draftReferralService">{item.service}</div>
                <div className="draftReferralMobileLabel">Service Provider</div>
                <div className="draftReferralServiceProvider">{item.provider}</div>
                <div className="draftReferralMobileLabel">Last edit</div>
                <div className="draftReferralEditDate">{Moment(item.edited).format("YYYY/MM/DD, h:mm a")}</div>
                <div className="draftReferralEditButton">
                  <button className="btn btn-std" style={{ padding: "7px", minWidth: "100px", margin: "0" }} onClick={() => setRedirect(item)}>
                    Edit
                  </button>
                </div>
                <div className="draftReferralDelete">
                  <img className="draftReferralDeleteIcon" src={trash} alt="trash icon" onClick={() => handleDelete(item.id)} />
                </div>
              </div>
            );
          })}
        </section>
      </div>
    </>
  );
};

export default DraftReferral;
