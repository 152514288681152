import React, { useContext } from "react";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import { urls } from "./utils/routes";

import UserContext from "./UserContext";
import Login from "./pages/Authentication/Login";
import ForgotPassword from "./pages/Authentication/ForgotPassword";
import AccountConfirmed from "./pages/Authentication/AccountConfirmed";
import AccountReConfirmed from "./pages/Authentication/AccountReConfirmed";
import NewPassword from "./pages/Authentication/NewPassword";
import SignUp from "./pages/Authentication/SignUp";
import Page404 from "./pages/Authentication/Page404";
import NewReferral from "./pages/NewReferral/NewReferral";
import NewReferralDetails from "./pages/NewReferral/NewReferralDetails";
import ServiceTermSheet from "./pages/NewReferral/ServiceTermSheet";
import DraftReferral from "./pages/DraftReferral/DraftReferral";
import Dashboard from "./pages/Dashboard/Dashboard";
import Logout from "./pages/Logout/Logout";
import FormContainer from "./pages/Forms/FormContainer";
import PresentationFormContainer from "./pages/Forms/PresentationFormContainer";

const PrivateRoute = ({ children, ...rest }) => {
  let location = useLocation();
  const user = useContext(UserContext);
  if (user.state.isAuthenticated) {
    return <Route {...rest} render={children} />;
  }
  return (
    <Redirect
      to={{
        pathname: urls.login,
        state: { from: location.pathname },
      }}
    />
  );
};

const Routes = () => {
  return (
    <Switch>
      <Route exact path={urls.login} component={Login} />
      <Route exact path={urls.forgotPassword} component={ForgotPassword} />
      <Route exact path={urls.newPassword + "/:code"} component={NewPassword} />
      <Route exact path={urls.signUp} component={SignUp} />
      <PrivateRoute exact path={urls.newReferral} component={NewReferral} />
      <PrivateRoute exact path={urls.newReferralDetails + "/:id"} component={NewReferralDetails} />
      <PrivateRoute exact path={urls.draftReferral} component={DraftReferral} />
      <PrivateRoute exact path={urls.serviceTermSheet + "/:employeeId/:serviceId"} component={ServiceTermSheet} />
      <PrivateRoute exact path={urls.dashboard} component={Dashboard} />
      <PrivateRoute exact path={urls.formContainer + "/:type"} component={FormContainer} />
      <Route exact path={urls.logout} component={Logout} />
      <Route exact path={urls.accountConfirmed + "/:code"} component={AccountConfirmed} />
      <Route exact path={urls.accountReConfirmed + "/:code"} component={AccountReConfirmed} />
      <Route exact path={urls.presentationForm + "/:id"} component={PresentationFormContainer} />
      <Route component={Page404} /> */
    </Switch>
  );
};

export default Routes;
