import React from "react";
import "./Modal.css";
import logo from "../../assets/logo.png";
import { MDBModal, MDBModalBody } from "mdbreact";

const ErrorModal = props => {
  return (
    <MDBModal isOpen={props.visible} size="md" centered>
      <MDBModalBody>
        <div className="stdModalBody">
          <div style={{ borderBottom: "1px solid #f4f4f5", textAlign: "center" }}>
            <img src={logo} className="authenticationLogo" alt="Great Oak logo" />
          </div>
          <div>
            <p style={{ margin: "20px 0 55px" }}>{props.text}</p>
            <div style={{ marginBottom: "16px" }}>
              <button className="btn btn-std btn-negative" onClick={props.close}>
                Close
              </button>
            </div>
          </div>
        </div>
      </MDBModalBody>
    </MDBModal>
  );
};

export default ErrorModal;
