import React from "react";
import "./RadioButton.css";
import { useFormikContext } from "formik";

const RadioButton = props => {
  const { setFieldValue } = useFormikContext();
  const checked = props.checked === props.value;
  return (
    <label className="radioContainer">
      <span className={`radioLabel ${checked && "radioChecked"}`}></span>
      <span dangerouslySetInnerHTML={{
        __html: props.label,
      }}></span>
      <input
        type="radio"
        name={props.name}
        value={props.value}
        checked={checked}
        disabled={props.disabled}
        onChange={() => setFieldValue(props.name, props.value)}
      />
    </label>
  );
};

export default RadioButton;
