import React, {useState} from "react";
import { Link } from "react-router-dom";
import "./Authentication.css";
import logo from "../../assets/logo.png";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {noJsonFetch} from "../../utils/fetch";
import ErrorComponent from "../../components/ErrorComponent";
import { urls } from "../../utils/routes";
import * as Yup from "yup";

const ForgotPassword = () => {
  const [error, setError] = useState(false);
  const [sucess, setSucess] = useState(false);

  const handleSubmit = (values, setSubmitting) => {
    const data = JSON.stringify({
      "email" : values.email,
    });
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: data,
    };
    noJsonFetch("user/resetPassword", options, true).then(response => {
      if (response.status === 200) {
				setSucess(true);
			} else {
				setError(true);
			}
      setSubmitting(false);
    }).catch(err => {
      setError(true);
      setSubmitting(false);
    });
  };
  
  return (
    <div className="mainAuthenticationContainer">
      <div className="authenticationBoxContainer">
        <div style={{ borderBottom: "1px solid #f4f4f5", textAlign: "center" }}>
          <img src={logo} className="authenticationLogo" alt="Great Oak logo" />
        </div>
        <div className="authenticationFormContainer">
          <Formik
            initialValues={{ email: "" }}
            validationSchema={Yup.object({
              email: Yup.string()
                .email("Invalid email address")
                .required("Required"),
            })}
            onSubmit={(values, { setSubmitting }) => {
              handleSubmit(values, setSubmitting);
            }}
          >
            {({ isSubmitting, isValid, dirty }) => (
              <Form>
                <h5 className="authenticationHeader">Forgot Password</h5>
                <p className="authenticationHeaderAdditional">To reset password, please enter your email adress. </p>
                <div className="inputContainer">
                  <label htmlFor="email">Email</label>
                  <Field type="email" name="email" />
                  <ErrorMessage className="errorComponent" name="email" component={ErrorComponent} />
                </div>
                {error && <div className="errorDiv">Something went wrong</div>}
                {sucess && <div className="successDiv">We sent an email with reset password link. Check your email</div>}
                <div className="text-center" style={{ marginTop: "40px" }}>
                  <button className="btn btn-std" type="submit" disabled={isSubmitting || !(isValid && dirty)}>
                    Reset
                  </button>
                </div>
                <div className="authorizationAdditionalLinks" style={{ marginTop: "30px" }}>
                  <span className="lightText">
                    If you remember password. <Link to={urls.login}>Sign In</Link>
                  </span>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
