import React, { useState } from "react";
import { MDBNavbar, MDBNavbarBrand } from "mdbreact";
import { BrowserRouter as Router, Link, NavLink } from "react-router-dom";
import ErrorModal from "./components/Modal/ErrorModal";
import fetchBasic, { auth, getStdOptions } from "./utils/fetch";
import { urls } from "./utils/routes";
import { ReactComponent as HamburgerIcon } from "./assets/3paski.svg";
import { ReactComponent as CloseIcon } from "./assets/close.svg";
import "react-datepicker/dist/react-datepicker.css";
import * as Yup from "yup";
import Moment from "moment";

import UserContext from "./UserContext";

import logo from "./assets/logo.png";
import Routes from "./Routes";
import "./App.css";

Yup.addMethod(Yup.date, "format", function (formats, parseStrict) {
  return this.transform(function (value, originalValue) {
    value = Moment(originalValue, formats, parseStrict);
    return value.isValid() ? value.toDate() : new Date("");
  });
});

const App = () => {
  const [userContext, setUserContext] = useState({});
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [requestError, setRequestError] = useState(false);

  if (!userContext.authenticationChecked) {
    auth.authenticate().then(response => {
      const authenticateResponse = response;
      const options = getStdOptions();
      return fetchBasic("user/items", options, true)  
      .then(response => {
          setUserContext({
            authenticationChecked: true,
            isAuthenticated: authenticateResponse.active,
            username: authenticateResponse.user_name, 
            email: authenticateResponse.user_name,
            draftCount: response.draftCount,
            referralCount: response.referralCount, 
          });
        })
        .catch(err => {
          setUserContext({
            authenticationChecked: true,
          });
          return err;
        });
    });
  }

  const updateIsAuthenticated = (authenticated, username) => {
    setUserContext({
      ...userContext,
      username: username, 
      email: username,
      isAuthenticated: authenticated,
    });
  };

  const updateDraftCount = (number) => {
    setUserContext({
      ...userContext,
      draftCount: userContext.draftCount + number,
    });
  };

  const setNewDraftCount = (number) => {
    setUserContext({
      ...userContext,
      draftCount: number,
    });
  };

  const setNewDraftReferralCount = (draft, referral) => {
    setUserContext({
      ...userContext,
      draftCount: draft,
      referralCount: referral,
    });
  };

  const updateReferralCount = (number) => {
    setUserContext({
      ...userContext,
      referralCount: userContext.referralCount + number,
    });
  };

  if (!userContext.authenticationChecked) {
    return null;
  }

  return (
    <Router>
      <UserContext.Provider value={{ state: userContext, updateIsAuthenticated, updateReferralCount, updateDraftCount, setNewDraftCount, setNewDraftReferralCount, setRequestError }}>
        <div className="flyout">
          <ErrorModal visible={requestError} text={requestError} close={() => setRequestError(false)} />
          <MDBNavbar color="white" dark expand="md" fixed="top" scrolling>
            <div className="navbarContainer">
              <MDBNavbarBrand href={urls.dashboard} className="py-0 font-weight-bold">
                <img src={logo} style={{ height: "42px", width: "106px" }} alt="Great Oak logo" />
              </MDBNavbarBrand>
              {userContext.isAuthenticated && (
                <>
                  <div className="navbarOptions">
                    <Link
                      to={urls.newReferral}
                      className="btn btn-std"
                      style={{ padding: "11px", minWidth: "140px", margin: "4px" }}
                    >
                      + New referral
                    </Link>
                    <div className="navbarInfo">
                      <NavLink exact to={urls.dashboard} activeClassName="bold">
                        File status <span className="navbarInfoNumber">{userContext.referralCount}</span>
                      </NavLink>
                    </div>
                    <div className="navbarInfo">
                      <NavLink exact to={urls.draftReferral} activeClassName="bold">
                        <span>Draft referrals</span>{" "}
                        <span className="navbarInfoNumber">{userContext.draftCount}</span>
                      </NavLink>
                    </div>
                    <div className="navbarProfile">
                      <p className="navbarEmail">{userContext.email}</p>
                      <Link to={urls.logout}>Logout &#10132;</Link>
                    </div>
                  </div>
                  <button className="hamburgerButton" onClick={() => setMobileMenuOpen(!mobileMenuOpen)}>
                    {!mobileMenuOpen ? <HamburgerIcon style={{ width: "19px", height: "15px" }} /> :
                    <CloseIcon style={{ width: "19px", height: "15px" }} />}
                  </button>
                </>
              )}
            </div>
            <div className="navbarMobileOptions" style={ !mobileMenuOpen ? { display:'none'} : {display : 'inherit'} }>
              <Link
                to={urls.newReferral}
                className="btn btn-std"
                style={{ padding: "11px", minWidth: "140px", margin: "4px" }}
              >
                + New referral
              </Link>
              <div className="navbarInfo">
                <Link to={urls.dashboard}>
                  File status <span className="navbarInfoNumber">{userContext.referralCount}</span>
                </Link>
              </div>
              <div className="navbarInfo">
                <Link to={urls.draftReferral}>
                  <span className="bold">Draft referrals</span>{" "}
                  <span className="navbarInfoNumber">{userContext.draftCount}</span>
                </Link>
              </div>
              <div className="navbarProfile">
                <p className="navbarEmail">{userContext.email}</p>
                <Link to={urls.logout}>Logout &#10132;</Link>
              </div>
            </div>
          </MDBNavbar>
          <main className="mainContainer">
            <Routes />
          </main>
        </div>
      </UserContext.Provider>
    </Router>
  );
};

export default App;
