import React from "react";
import "./Checkbox.css";

const Checkbox = props => {
  return (
    <label className="checkboxContainer">
      <span className={`checkboxLabel ${props.value && "checkboxChecked"}`}></span>
      <span>{props.label}</span>
      <input type="checkbox" name={props.name} value={props.value} onChange={props.onChange} />
    </label>
  );
};

export default Checkbox;
