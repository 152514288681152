import React, {useState} from "react";
import { Link } from "react-router-dom";
import "./NewReferralBox.css";
import { urls } from "../../utils/routes";

const NewReferralBox = props => {
  const [expand, setExpand] = useState(false)
  return (
    <div className={`referralBox ${props.stdView ? '' : 'referralBoxNarrowView'}`}>
      <div className="titleContainer">
        <h2>{props.title}</h2>
        <div style={{display: "flex"}}>
          <span className="referralBoxShowDetails" onClick={() => setExpand(!expand)}>
            {expand ? "Hide Details" : "Show details"} 
            <span className={`referralDowntick ${expand ? 'downtickExpand' : ''}`}>&#x2039;</span>
          </span>
          <Link
            to={`${urls.newReferralDetails}/${props.id}`}
            className="btn btn-std"
            style={{ padding: "11px", minWidth: "94px", margin: "0px" }}
          >
            Select
          </Link>
        </div>
      </div>
      {expand && <div className="contentContainer">
        
        <p
          dangerouslySetInnerHTML={{
            __html: props.description,
          }}
        ></p>
      </div>}
    </div>
  );
};

export default NewReferralBox;
