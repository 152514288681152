import React, { useEffect, useState, useContext } from "react";
import UserContext from "../../UserContext";
import NewReferralBox from "../../components/NewReferralBox/NewReferralBox";
import fetchBasic, { getStdOptions } from "../../utils/fetch";
import "./NewReferral.css";

const NewReferral = () => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const [stdViewActive, setStdViewActive] = useState(true);
  const [data, setData] = useState([]);
  const user = useContext(UserContext);

  useEffect(() => {
    const options = getStdOptions();
    fetchBasic("service/services", options, true)
      .then(response => {
        if (response.tokenRefreshed) {
          return fetchBasic("service/services", getStdOptions(), false)
            .then(response => {
              setDataLoaded(true);
              setData(response);
            })
            .catch(err => {
              return err;
            });
        } else {
          setDataLoaded(true);
          setData(response);
        }
      })
      .catch(err => {
        user.setRequestError(err.message);
        return err; 
      });
  }, []);
  return (
    <>
      <a target="_blank" href="https://api.greatoakvfa.com/banner" rel="noopener noreferrer"><img className="adBaner" src="https://api.greatoakvfa.com/static/banner.png" alt=""/></a> 
      <div className="mainReferralContainer">
        <div className="newReferralHeaderContainer">
          <h1>New Referral</h1>
          <div className={`changeViewBox ${stdViewActive ? 'stdViewActive' : 'boxViewActive' }`}>
            <div className="stdView" onClick={() => setStdViewActive(true)}>
              <span></span>
              <span></span>
              <span></span>
            </div>
            <div className="boxView" onClick={() => setStdViewActive(false)}>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
        <section className={`${stdViewActive ? '' : 'boxViewSectionContainer'}`}>
          {!data.length && dataLoaded && <h4>No referrals</h4>}
          {data.map(item => {
            return <NewReferralBox title={item.title} description={item.description} id={item.id} key={item.id} stdView={stdViewActive} />;
          })}
        </section>
      </div>
    </>
  );
};

export default NewReferral;
