import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getDraftPresentationData } from "../../utils/fetch"
import IrbContent from "./IrbContent"
import LoiContent from "./LoiContent"
import VocContent from "./VocContent"
import MedContent from "./MedContent"
import "./Forms.css";
 
const PresentationFormContainer = () => {
	let { id } = useParams();
	const [draftData, setDraftData] = useState(null);
  const [error, setError] = useState(false);
	useEffect(() => {
		if (id) {
      getDraftPresentationData(id).then(res => {
				setDraftData(res);
			}).catch(err => {
				setError(true);
				return err;
      });
		}
  }, [id]);
  if (error) {
    return (
      <h1>Form not found</h1>
    )
	}
	
  if (!draftData) {
    return (
      <h1>...loading</h1>
    )
  }

  let oldFormData = {}
  if (draftData.form) {
    const formParsed = JSON.parse(draftData.form);
    Object.keys(formParsed).forEach((key) => {
      oldFormData[key] = formParsed[key].value;
    });
    if ("employmentStatus" in oldFormData && !oldFormData.employmentStatus) {
      oldFormData.employmentStatus = [];
    }
  }

  const renderForm = () => {
    switch (draftData.formCode.toLowerCase()) {
      case 'irb':
        return (<IrbContent oldFormData={oldFormData} presentationForm={true} />)
      case 'loi':
        return (<LoiContent oldFormData={oldFormData} presentationForm={true} />)
      case 'lois':
        return (<LoiContent oldFormData={oldFormData} presentationForm={true} />)
      case 'voc':
        return (<VocContent oldFormData={oldFormData} presentationForm={true} />)
      case 'med':
        return (<MedContent oldFormData={oldFormData} presentationForm={true} />)
      default:
        return null
    }
  }

  return (
    <div className="mainFormContainer">
      <p>New referral / {draftData.code}</p>
      <h1>/ {draftData.title}</h1>
      <section className="formBoxContainer">
        {renderForm()}
      </section>
    </div>
  );
};

export default PresentationFormContainer;


// cms/draftCode?id=1 - generuje kod jednorazowy dla draft o podanym Id. Aktualnie ten endpoint jest dostępny publicznie na potrzeby implementacji.

// cms/draft?code=e6c1f10 - zwrócony wrzucamy w ten endpoint. Zwraca on taką samą strukturę jak ten od pobierania szczegółów szkicu, nie zawiera tylko plików, bo do podglądu w cms nie są nam potrzebne. Aktualnie ten endpoint również jest dostępny publicznie na potrzeby implementacji.