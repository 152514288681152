export const urls = {
	dashboard: "/",
	login: "/login",
	forgotPassword: "/forgotPassword",
	newPassword: "/newPassword",
	signUp: "/signUp",
	newReferral: "/newReferral",
	newReferralDetails: "/newReferralDetails",
	draftReferral: "/draftReferral",
	serviceTermSheet: "/serviceTermSheet",
	formContainer: "/formContainer",
	logout: "/logout",
	accountConfirmed: "/confirmAccount",
	presentationForm: "/presentationForm",
	accountReConfirmed: "/reConfirmAccount"
}



