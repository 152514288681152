import React, { useState, useContext } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useCreateDraft, useGetDraft} from "../../utils/hooks"
import { saveStsFile } from "../../utils/fetch"
import { urls } from "../../utils/routes";
import UserContext from "../../UserContext";
import { Redirect } from "react-router-dom";
import IrbContent from "./IrbContent"
import LoiContent from "./LoiContent"
import VocContent from "./VocContent"
import MedContent from "./MedContent"
import "./Forms.css";
 
const FormContainer = () => {
	let location = useLocation();
	const { type } = useParams();
  const [redirect, setRedirect] = useState(false);
  const user = useContext(UserContext);
  const employeeId = location.state && location.state.employeeId ? location.state.employeeId : 0;
  const serviceId = location.state && location.state.serviceId ? location.state.serviceId : 0;
  const oldDraftId = location.state && location.state.draftId ? location.state.draftId : "";

  const stsFile = oldDraftId || !location.state || !location.state.stsFile ? "" : location.state.stsFile;

  let loadedFiles = [];
  const draftId = useCreateDraft({
    employeeId, serviceId, draftId: oldDraftId
  });
  const draftData = useGetDraft(draftId)
  if(redirect) {
    return (
      <Redirect
      to={{
        pathname: urls.draftReferral,
      }}
    />
    )
  }
  if (!employeeId && !serviceId && !draftId) {
    return (
      <h1>Please create new form or edit existing one</h1>
    )
  }

  if (!draftData) {
    return (
      <h1>...loading</h1>
    )
  }

  let oldFormData = {}
  if (draftData.form) {
    const formParsed = JSON.parse(draftData.form);
    Object.keys(formParsed).forEach((key) => {
      oldFormData[key] = formParsed[key].value;
    });
    if ("employmentStatus" in oldFormData && !oldFormData.employmentStatus) {
      oldFormData.employmentStatus = [];
    }
  }

  if (draftData.files) {
    loadedFiles = draftData.files.map(item => {
      return {
        name: item.name,
        uuid: item.uuid
      }
    })
  }
  if (stsFile) {
    saveStsFile(stsFile, draftId).then(response => {
    }).catch(err => {
      user.setRequestError(`Saving service term sheet failed - ${err.message}`);
    })
  }

  const renderForm = () => {
    switch (type) {
      case 'irb':
        return (<IrbContent draftId={draftId} loadedFiles={loadedFiles} oldFormData={oldFormData} setRedirect={setRedirect} />)
      case 'loi':
        return (<LoiContent draftId={draftId} loadedFiles={loadedFiles} oldFormData={oldFormData} setRedirect={setRedirect} />)
      case 'lois':
        return (<LoiContent draftId={draftId} loadedFiles={loadedFiles} oldFormData={oldFormData} setRedirect={setRedirect} />)
      case 'voc':
        return (<VocContent draftId={draftId} loadedFiles={loadedFiles} oldFormData={oldFormData} setRedirect={setRedirect} />)
      case 'med':
        return (<MedContent draftId={draftId} loadedFiles={loadedFiles} oldFormData={oldFormData} setRedirect={setRedirect} />)
      default:
        return null
    }
  }

  return (
    <>
      <a target="_blank" href="https://api.greatoakvfa.com/banner" rel="noopener noreferrer"><img className="adBaner" src="https://api.greatoakvfa.com/static/banner.png" alt=""/></a> 
      <div className="mainFormContainer">
        <p>New referral / {draftData.code}</p>
        <h1>/ {draftData.title}</h1>
        <section className="formBoxContainer">
          {renderForm()}
        </section>
      </div>
    </>
  );
};

export default FormContainer;
