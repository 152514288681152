export function dataURLtoFile(dataurl, filename) {
 
  var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), 
      n = bstr.length, 
      u8arr = new Uint8Array(n);
      
  while(n--){
      u8arr[n] = bstr.charCodeAt(n);
  }
  
  return new File([u8arr], filename, {type:mime});
}

export async function getPass(message) {
  // encode as UTF-8
  const msgBuffer = new TextEncoder('utf-8').encode(message);                    
  // hash the message
  var cryptoObj = window.crypto || window.msCrypto;
  const hashBuffer = await cryptoObj.subtle.digest('SHA-256', msgBuffer);
  
  let hashArray;
  if (hashBuffer.result) {
    hashArray = Array.from(new Uint8Array(hashBuffer.result));
  } else {
    hashArray = Array.from(new Uint8Array(hashBuffer));
  }
  // convert bytes to hex string                  
  const hashHex = hashArray.map(b => ('00' + b.toString(16)).slice(-2)).join('');
  return hashHex;
}

export function dashboardAmountToNumber(x) {
  if (!isNaN(Number(x))) {
    return Number(x);
  }
  const amountWithDot = x.replace(/,/g, '.');
  return Number(amountWithDot);
}

export function numberWithCommas(x) {
  return x
    .toFixed(2) 
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const formLabels = {
  firstName: "First Name",
  lastName: "Last Name",
  gender: "Gender",
  address: "Address",
  city: "City",
  province: "Province",
  postalCode: "Postal Code",
  tel: "Telephone Number",
  altTel: "Alt. Telephone Number",
  email: "Email",
  altFirstName: "First Name",
  altLastName: "Last Name",
  altTelNumber: "Telephone Number",
  dateOfIncident: "Date of Loss/Incident",
  irb: "Has the Claimant elected to receive Income Replacement Benefits?",
  dob: "Date of Birth",
  additionalInfo: "Additional Comments/Special Instructions",
  insuranceCompany: "Insurance Company",
  adjusterName: "Adjuster Name",
  insuranceTel: "Telephone",
  insuranceFax: "FAX",
  insuranceEmail: "E-mail",
  claimNumber: "Claim Number",
  policyNumber: "Policy Number",
  policyHolder: "Policy Holder (If different from Claimant)",
  employmentStatus: "Pre-Accident Employment Status (Select all that apply)",
  ocf1: "Has an OCF-1 (Application for Accident Benefits) been completed for this Claimant?",
  ocf2: "Has an OCF-2 (Employer's Confirmation) been completed for this Claimant?",
  ocf3: "Has an OCF-3 (Disability Certificate) been completed for this Claimant?",
  currentIRB: "Is the client currently receiving IRB's?",
  otherAssitance: "Has the client received any other Assisstance (i.e. STD/LTD/CPPD)?",
  otherAssitanceInfo: "Please provide information on other assistance",
  valuationDate: "Valuation Date (i.e Trial Date or Mediation Date)",
  repNeededBy: "Report Needed By",
  incidentType: "Incident Type",
  otherIncidentType: "Please provide incident type",
  martialStatus: "Martial Status",
  calcType: "Calculation Type",
  retirementAge: "Intended Age of Retirement",
  altAge: "Please suggest age and explain",
  education: "Highest Completed Education (If Known)",
  irbLoi: "Has the client received any Income Replacement Benefits (IRB's)?",
  assistance: "Has the client received any other assisstance (i.e. CPP/STD/LTD/Disability)?",
  additionalAssistance: "Please explain",
  employmentReturn: "Employment Status Post-Accident:",
  employmentReturnDate: "Date returned to work (if applicable and known)",
  futureLoss: "Future Loss Scenario",
  lang: "Language Spoken",
  otherLang: "Please provide language",
  pov: "Purpose of Vocational (Choose One)",
  preInjuryOccupation: "Pre-Injury Occupation",
  diagnosis: "What is subject’s primary & secondary diagnosis",
  prognosis: "What is subject’s prognosis?",
  barriers: "What are the present medical barriers to the subject's recovery, if any?",
  ableToWork: "Is the subject able to resume their pre-accident employment duties at this time? If not, when will the subject be capable of doing so?",
  restrictions: "What are the subject's present physical restrictions, if any? And how are those restrictions being addressed by way of treatment?",
  requireMedication: "Does the subject require medication?",
  requirePhysioterapy: "Does the subject require physiotherapy treatment of any kind? If so, what is the frequency and duration recommended?",
  pathology: "Can the subject's reported symptoms be supported by the existence of objective clinical pathology?",
  therapeuticValue: "Is the subject's treatment reasonable as to benefit & therapeutic value?",
  furtherInvestigation: "Is there suspected abnormal underlying pathology requiring further medical investigation? If yes, please explain.",
  maximumRecovery: "Has maximum medical recovery been achieved? If not, please indicate an anticipated time frame. If MMR has been reached, please provide an explanation for concluding that the condition is stable and stationary and unlikely to change.",
  furtherTreatmentRecommended: "Is any further treatment/assistive devices recommended to reduce and/or eliminate present physical restrictions? If so, please indicate type & frequency.",
  furtherConsiderations: "Are there any further suggested considerations or recommendations in order to enhance the subject's return to maximum functioning?",
  transportationServices: "Does the subject require transportation services (e.g. taxi) to attend medical appointments?",
  concurDiagnosis: "Does the provider concur with the medical diagnosis/impairment descriptions that have been provided to date?",
  preexistingConditions: "Are there any pre-existing psychological conditions? If not, please explain.",
  psychologicalStatus: "What is the present psychological status with the resulting DSM IV code? Is this permanent or temporary?",
  cognitiveDeficits: "Do objective findings indicate any cognitive deficits as a result of the accident? If yes, please indicate the estimated duration of impairment.",
  cognitionIntact: "Are the subject's cognitive abilities intact and is there normal thought process present? Please comment.",
  psychologicalAttention: "Is there functional overlay requiring psychological attention or assessment?",
  progressRate: "Is the rate of progress satisfactory in this case?",
  furtherMedicalInvestigation: "Is further medical investigation required? If so, what investigations and why?",
  recommendedTreatmentFollowed: "Has the subject been following the recommended treatment protocol prior to examination?",
  recoveryImpediments: "Is anything impeding further or a more timely recovery?",
}

export const prepareDraftToSave = (values) => {
  const clientName = `${values.firstName} ${values.lastName}`
  const newValues = {}
  Object.keys(values).forEach((key) => {
    const val = values[key];
    if (val.length !== 0) {
      newValues[key] = {
        value: val,
        label: formLabels[key]
      }
    }
  });
  return {
    clientName,
    newValues
  }
}