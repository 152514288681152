import React, { useState, useContext } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import "./Authentication.css";
import logo from "../../assets/logo.png";
import { Formik, Form, Field, ErrorMessage } from "formik";
import ErrorComponent from "../../components/ErrorComponent";
import UserContext from "../../UserContext";
import fetchBasic, { auth, getAuthData } from "../../utils/fetch";
import { getPass } from "../../utils/utils";
import { urls } from "../../utils/routes";
import * as Yup from "yup";

const Login = () => {
  let history = useHistory();
  let location = useLocation();
  const user = useContext(UserContext);
  const [error, setError] = useState(false);
  let { from } = location.state || { from: { pathname: urls.dashboard } };
  const handleSubmit = async (values, setSubmitting) => {
    const pass = await getPass(values.password);
    const data = new URLSearchParams({
      grant_type: "password",
      username: values.email,
      password: pass,
    });
    const options = {
      method: "POST",
      headers: {
        Authorization: "Basic " + getAuthData(),
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: data,
    };
    fetchBasic("oauth/token", options, true).then(response => {
      if (response.access_token) {
        localStorage.setItem("oak_token", response.access_token);
        localStorage.setItem("oak_ref", response.refresh_token);
        auth.authenticate().then(response => {
          user.updateIsAuthenticated(response.active, response.email);
          history.replace(from)
          return response;
        })
      } else {
        setSubmitting(false);
        setError("Wrong credentials");
        return response;
      }
    }).catch(err => {
      setError(err.message);
      setSubmitting(false);
    });
  };

  if (user.state.isAuthenticated) {
    history.replace(from);
  }

  return (
    <div className="mainAuthenticationContainer">
      <div className="authenticationBoxContainer">
        <div style={{ borderBottom: "1px solid #f4f4f5", textAlign: "center" }}>
          <img src={logo} className="authenticationLogo" alt="Great Oak logo" />
        </div>
        <div className="authenticationFormContainer">
          <Formik
            initialValues={{ email: "", password: "" }}
            validationSchema={Yup.object({
              email: Yup.string()
                .email("Invalid email address")
                .required("Required"),
              password: Yup.string()
                .min(8, "Must be at least 8 characters")
                .required("Required"),
            })}
            onSubmit={(values, { setSubmitting }) => {
              handleSubmit(values, setSubmitting);
            }}
          >
            {({ isSubmitting, isValid, dirty }) => (
              <Form>
                <h5 className="authenticationHeader">Log in</h5>
                <div className="inputContainer">
                  <label htmlFor="email">Email</label>
                  <Field type="email" name="email" />
                  <ErrorMessage className="errorComponent" name="email" component={ErrorComponent} />
                </div>
                <div className="inputContainer">
                  <label htmlFor="password">Password</label>
                  <Field type="password" name="password" />
                  <ErrorMessage className="errorComponent" name="password" component={ErrorComponent} />
                </div>
                {error && <div className="errorDiv">{error}</div>}
                <div className="text-center" style={{ marginTop: "40px" }}>
                  <button className="btn btn-std" type="submit" disabled={isSubmitting || !(isValid && dirty)}>
                    Log in
                  </button>
                </div>
                <div className="authorizationAdditionalLinks" style={{ margin: "30px 0" }}>
                  <Link to={urls.forgotPassword}>Forgot password?</Link>
                </div>
                <div className="authorizationAdditionalLinks">
                  <span className="lightText">
                    Don’t have an account? <Link to={urls.signUp}>Sign Up</Link>
                  </span>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default Login;
