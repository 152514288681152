import React from "react";
import { Link } from "react-router-dom";
import { urls } from "../../utils/routes";
import "./NewReferralBox.css";

const NewReferralBoxDetails = props => {
  const { name, credentials, expertise, reportSpecialization, hightlights, id } = props.item;
  return (
    <div className="referralBox referralBoxDetails">
      <div className="titleContainer">
        <h2>{name}</h2>
        <Link
          to={`${urls.serviceTermSheet}/${id}/${props.parentId}`}
          className="btn btn-std"
          style={{ padding: "11px", minWidth: "94px", margin: "0px" }}
        >
          Select
        </Link>
      </div>
      <div className="contentContainer">
        <p className="referralBoxDetailsLabel">Credentials</p>
        <p className="referralBoxDetailsInfo">{credentials}</p>
        <p className="referralBoxDetailsLabel">Expertise</p>
        <p className="referralBoxDetailsInfo">{expertise}</p>
        <p className="referralBoxDetailsLabel">Report Specialization</p>
        <p className="referralBoxDetailsInfo">{reportSpecialization}</p>
        <p className="referralBoxDetailsLabel" style={{ marginBottom: "12px" }}>
          Highlights:
        </p>
        <ul>
          {hightlights &&
            hightlights.map((hightlight, id) => {
              return <li key={id}>{hightlight}</li>;
            })}
        </ul>
      </div>
    </div>
  );
};

export default NewReferralBoxDetails;
