import { useState, useEffect, useContext } from 'react';
import fetchBasic, { postStdOptions, getDraftData } from "./fetch";
import UserContext from "../UserContext";

export function useCreateDraft(props) {
	const {employeeId, serviceId, draftId} = props;
	const [newDraftId, setNewDraftId] = useState(draftId);
	const user = useContext(UserContext);

  useEffect(() => {
    if (employeeId && serviceId && !draftId) {
      const data = {
          serviceId,
          employeeId
      };
      const json = JSON.stringify(data);
      const options = postStdOptions(json);
      fetchBasic("draft/create", options, true)
      .then(response => {
        if (response.tokenRefreshed) {
          return fetchBasic("draft/create", postStdOptions(json), false)
            .then(response => {
							setNewDraftId(response.id);
							user.updateDraftCount(1);
            })
            .catch(err => {
              return err;
            });
        } else {
					setNewDraftId(response.id);
					user.updateDraftCount(1);
        }
      })
      .catch(err => {
        user.setRequestError(err.message);
      });
    }
  }, [employeeId, serviceId, draftId]);
	return newDraftId;
}

export function useGetDraft(draftId) {
	const [draftData, setDraftData] = useState(null);
	const user = useContext(UserContext);

  useEffect(() => {
		if (draftId) {
      getDraftData(draftId).then(response => {
        setDraftData(response);
      }).catch(err => {
        user.setRequestError(err.message);
        return err;
      });
		}
  }, [draftId]);

	return draftData;
}