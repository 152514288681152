import React, { useCallback, useState, useContext, useRef, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import ErrorComponent from "../../components/ErrorComponent";
import FormikSelect from "../../components/FormikSelect";
import UserContext from "../../UserContext";
import DatePickerField from "../../components/DatePickerField";
import { getDraftData } from "../../utils/fetch";
import { useDropzone } from "react-dropzone";
import { saveDraft, saveDraftFile, deleteDraftFile, saveFormScreenshot } from "../../utils/fetch";
import { formLabels, prepareDraftToSave, dataURLtoFile } from "../../utils/utils";
import FormikCheckbox from "../../components/FormikCheckbox";
import RadioButton from "../../components/RadioButton";
import Moment from "moment";
import * as Yup from "yup";
import fileUpload from "../../assets/fileUpload.png";
import "./Forms.css";
import { toPng } from 'dom-to-image';

const IrbContent = ({draftId, loadedFiles, oldFormData, setRedirect, presentationForm}) => {
	const [uploadedFiles, setUploadedFiles] = useState(loadedFiles ? loadedFiles : []);
	const [uploading, setUploading] = useState(false);
	const user = useContext(UserContext);
	let formValuesToSave = useRef(oldFormData);
	let lastUpdate = useRef(Moment(new Date()).format("h:mm a"));
	useEffect(() => {
		if (presentationForm) {
			return;
		}
		const timer = setInterval(() => {
			const formData = prepareDraftToSave(formValuesToSave.current);
			saveDraft(draftId, formData.clientName, formData.newValues).then(response => {
				lastUpdate.current = Moment(new Date()).format("h:mm a");
			})
		}, 20000);
		return () => { clearInterval(timer) };
	}, [draftId, presentationForm]);

  const onDrop = useCallback((acceptedFiles) => {
		const requests = acceptedFiles.map(file => saveDraftFile(draftId, file));
		Promise.all(requests).catch(err => {
			user.setRequestError(err.message.startsWith("Error: Maximum upload size exceeded;") ? "Maximum upload size exceeded" : err.message);
		}).finally(() => {
			getDraftData(draftId).then(response => {
				setUploadedFiles(response.files)
				setUploading(false);
      }) 
		});
	}, [draftId]);
	
	const deleteFile = (uuid) => {
		deleteDraftFile(uuid).then(() => {
			const newFiles = uploadedFiles.filter(file => file.uuid !== uuid);
			setUploadedFiles(newFiles)
		}).catch(err => {
			user.setRequestError(err.message);
		})
	} 

	const onDropAccepted = () => {
		setUploading(true);
	}
	
	const onDropRejected = (file) => {
		user.setRequestError(`The file is too big.`);
	}

  let { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, onDropRejected, onDropAccepted, maxSize: 104857600 }); 

  const files = uploadedFiles.map((file) => (
    <li key={file.uuid}>
      {file.name} <span onClick={() => deleteFile(file.uuid)} className="deleteFile">+</span>
    </li>
	));

	return (
		<Formik
			validateOnMount={true}
			initialValues={{
				firstName: "",
				lastName: "",
				gender: "",
				address: "",
				city: "",
				province: "",
				postalCode: "",
				tel: "",
				altTel: "",
				email: "",
				altFirstName: "",
				altLastName: "",
				altTelNumber: "",
				dateOfIncident: "",
				irb: "",
				dob: "",
				additionalInfo: "",
				insuranceCompany: "",
				adjusterName: "",
				insuranceTel: "",
				insuranceFax: "",
				insuranceEmail: "",
				claimNumber: "",
				policyNumber: "",
				policyHolder: "",
				employmentStatus: [],
				ocf1: "",
				ocf2: "",
				ocf3: "",
				currentIRB: "",
				otherAssitance: "",
				otherAssitanceInfo: "",
				...oldFormData
			}}
			validationSchema={Yup.object({
				firstName: Yup.string().required("Required"),
				lastName: Yup.string().required("Required"),
				tel: Yup.string().required("Required"),
				dateOfIncident: Yup.date().required("Required").format("YYYY/MM/DD").typeError("Wrong date format"),
				email: Yup.string().email("Invalid email address"),
				insuranceEmail: Yup.string().email("Invalid email address"),
				dob: Yup.date().format("YYYY/MM/DD").typeError("Wrong date format"),
			})}
			onSubmit={(values, { setSubmitting }) => {
				const formData = prepareDraftToSave(values);
				toPng(document.getElementById('divToCopy'), { bgcolor: "#ffffff", quality: 0.65 }).then(file => {
					const formAsFile = dataURLtoFile(file, "Form.png")
					return saveFormScreenshot(formAsFile, draftId)
				}).then(() => {
					return saveDraft(draftId, formData.clientName, formData.newValues, true)
				})
				.then(response => {
						setRedirect(true);
				}).catch(err => {
					user.setRequestError(err.message);
				});
			}}
		>
			{({ isSubmitting, isValid, dirty, values }) => {
				formValuesToSave.current = values;
				return(
				<Form id={"divToCopy"}>
					{!presentationForm && <div className="lastUpdate">
						<span className="formUpdateBullet"></span> Auto saved in Drafts at: {lastUpdate.current}
					</div>}
					<h2>I. Claimant Information</h2>
					<div className="formRow">
						<div className="formCol">
							<div className="inputContainer">
								<label htmlFor="firstName">1. {formLabels.firstName}*</label>
								<Field type="text" name="firstName" disabled={presentationForm}/>
								<ErrorMessage className="errorComponent" name="firstName" component={ErrorComponent} />
							</div>
							<div className="inputContainer">
								<label htmlFor="lastName">2. {formLabels.lastName}*</label>
								<Field type="text" name="lastName" disabled={presentationForm}/>
								<ErrorMessage className="errorComponent" name="lastName" component={ErrorComponent} />
							</div>
						</div>
						<div className="formCol">
							<div className="inputContainer">
								<label htmlFor="tel">3. {formLabels.tel}*</label>
								<Field type="text" name="tel" disabled={presentationForm}/>
								<ErrorMessage className="errorComponent" name="tel" component={ErrorComponent} />
							</div>
							<div className="inputContainer">
								<label htmlFor="dateOfIncident">4. {formLabels.dateOfIncident}*</label>
								<DatePickerField name="dateOfIncident" disabled={presentationForm}/>
								<ErrorMessage className="errorComponent" name="dateOfIncident" component={ErrorComponent} />
							</div>
						</div>
					</div>
					<div className="formRow">
						<div className="formCol">
							<div className="inputContainer inputWideContainer">
								<label htmlFor="address">5. {formLabels.address}</label>
								<Field type="text" name="address" disabled={presentationForm}/>
								<ErrorMessage className="errorComponent" name="address" component={ErrorComponent} />
							</div>
						</div>
						<div className="formCol">
							<div className="inputContainer">
								<label htmlFor="city">6. {formLabels.city}</label>
								<Field type="text" name="city" disabled={presentationForm}/>
								<ErrorMessage className="errorComponent" name="city" component={ErrorComponent} />
							</div>
							<div className="inputContainer">
								<label htmlFor="province">7. {formLabels.province}</label>
								<Field type="text" name="province" disabled={presentationForm}/>
								<ErrorMessage className="errorComponent" name="province" component={ErrorComponent} />
							</div>
						</div>
					</div>
					<div className="formRow">
						<div className="formCol">
							<div className="inputContainer">
								<label htmlFor="postalCode">8. {formLabels.postalCode}</label>
								<Field type="text" name="postalCode" disabled={presentationForm}/>
								<ErrorMessage className="errorComponent" name="postalCode" component={ErrorComponent} />
							</div>
						</div>
					</div>
					<div className="formRow">
						<div className="formCol">
							<div className="inputContainer">
								<label htmlFor="dob">9. {formLabels.dob}</label>
								<DatePickerField name="dob" disabled={presentationForm}/>
								<ErrorMessage className="errorComponent" name="dob" component={ErrorComponent} />
							</div>
							<div className="inputContainer">
								<label htmlFor="altTel">10. {formLabels.altTel}</label>
								<Field type="text" name="altTel" disabled={presentationForm}/>
								<ErrorMessage className="errorComponent" name="altTel" component={ErrorComponent} />
							</div>
						</div>
						<div className="formCol">
							<div className="inputContainer">
								<label htmlFor="email">11. {formLabels.email}</label>
								<Field type="email" name="email" disabled={presentationForm}/>
								<ErrorMessage className="errorComponent" name="email" component={ErrorComponent} />
							</div>
							<div className="inputContainer">
								<label htmlFor="gender">12. {formLabels.gender}</label>
								<FormikSelect
									id="gender"
									name="gender"
									type="text"
									disabled={presentationForm}
									options={[
										{ value: "female", label: "Female" },
										{ value: "male", label: "Male" },
									]}
								/>
							</div>
						</div>
					</div>
					<h2>Alt. Contact Person</h2>
					<div className="formRow">
						<div className="formCol">
							<div className="inputContainer">
								<label htmlFor="altFirstName">13. {formLabels.altFirstName}</label>
								<Field type="text" name="altFirstName" disabled={presentationForm}/>
								<ErrorMessage className="errorComponent" name="altFirstName" component={ErrorComponent} />
							</div>
							<div className="inputContainer">
								<label htmlFor="altLastName">14. {formLabels.altLastName}</label>
								<Field type="text" name="altLastName" disabled={presentationForm}/>
								<ErrorMessage className="errorComponent" name="altLastName" component={ErrorComponent} />
							</div>
							</div>                
						<div className="formCol">
							<div className="inputContainer">
								<label htmlFor="altTelNumber">15. {formLabels.altTelNumber}</label>
								<Field type="text" name="altTelNumber" disabled={presentationForm}/>
								<ErrorMessage className="errorComponent" name="altTelNumber" component={ErrorComponent} />
							</div>
						</div>
					</div>
					<h2>II. Insurance Information</h2>
					<div className="formRow">
						<div className="formCol">
							<div className="inputContainer">
								<label htmlFor="insuranceCompany">16. {formLabels.insuranceCompany}</label>
								<Field type="text" name="insuranceCompany" disabled={presentationForm}/>
								<ErrorMessage className="errorComponent" name="insuranceCompany" component={ErrorComponent} />
							</div>
							<div className="inputContainer">
								<label htmlFor="adjusterName">17. {formLabels.adjusterName}</label>
								<Field type="text" name="adjusterName" disabled={presentationForm}/>
								<ErrorMessage className="errorComponent" name="adjusterName" component={ErrorComponent} />
							</div>
						</div>
						<div className="formCol">
							<div className="inputContainer">
								<label htmlFor="insuranceTel">18. {formLabels.insuranceTel}</label>
								<Field type="text" name="insuranceTel" disabled={presentationForm}/>
								<ErrorMessage className="errorComponent" name="insuranceTel" component={ErrorComponent} />
							</div>
							<div className="inputContainer">
								<label htmlFor="insuranceFax">19. {formLabels.insuranceFax}</label>
								<Field type="text" name="insuranceFax" disabled={presentationForm}/>
								<ErrorMessage className="errorComponent" name="insuranceFax" component={ErrorComponent} />
							</div>
						</div>
					</div>
					<div className="formRow">
						<div className="formCol">
							<div className="inputContainer inputWideContainer">
								<label htmlFor="insuranceEmail">20. {formLabels.insuranceEmail}</label>
								<Field type="email" name="insuranceEmail" disabled={presentationForm}/>
								<ErrorMessage className="errorComponent" name="insuranceEmail" component={ErrorComponent} />
							</div>
						</div>
						<div className="formCol">
							<div className="inputContainer inputWideContainer">
								<label htmlFor="claimNumber">21. {formLabels.claimNumber}</label>
								<Field type="text" name="claimNumber" disabled={presentationForm}/>
								<ErrorMessage className="errorComponent" name="claimNumber" component={ErrorComponent} />
							</div>
						</div>
					</div>
					<div className="formRow">
						<div className="formCol">
							<div className="inputContainer inputWideContainer">
								<label htmlFor="policyNumber">22. {formLabels.policyNumber}</label>
								<Field type="text" name="policyNumber" disabled={presentationForm}/>
								<ErrorMessage className="errorComponent" name="policyNumber" component={ErrorComponent} />
							</div>
						</div>
						<div className="formCol">
							<div className="inputContainer inputWideContainer">
								<label htmlFor="policyHolder">23. {formLabels.policyHolder}</label>
								<Field type="text" name="policyHolder" disabled={presentationForm}/>
								<ErrorMessage className="errorComponent" name="policyHolder" component={ErrorComponent} />
							</div>
						</div>
					</div>
					<h2>III. Additional Information</h2>
					<div className="formRow">
						<div className="formCol">
							<div className="inputContainer inputWideContainer">
								<p className="label">24. {formLabels.employmentStatus}</p>
								<FormikCheckbox value="employed" label="Employed" name="employmentStatus" checked={values.employmentStatus} disabled={presentationForm}/>
								<FormikCheckbox
									value="selfEmployed"
									label="Self-Employed"
									name="employmentStatus"
									checked={values.employmentStatus}
									disabled={presentationForm}
								/>
								<FormikCheckbox value="unemployed" label="Unemployed" name="employmentStatus" checked={values.employmentStatus} disabled={presentationForm}/>
							</div>
						</div>
						<div className="formCol">
							<div className="inputContainer inputWideContainer">
								<p className="label">25. {formLabels.irb}</p>
								<RadioButton value="yes" label="Yes" name="irb" checked={values.irb} disabled={presentationForm}/>
								<RadioButton value="no" label="No" name="irb" checked={values.irb} disabled={presentationForm}/>
							</div>
						</div>
					</div>
					<div className="formRow">
						<div className="formCol">
							<div className="inputContainer inputWideContainer">
								<p className="label">26. {formLabels.ocf1}</p>
								<RadioButton value="yes" label="Yes; Please attach the OCF-1" name="ocf1" checked={values.ocf1} disabled={presentationForm}/>
								<RadioButton value="no" label="No" name="ocf1" checked={values.ocf1} disabled={presentationForm}/>
							</div>
						</div>
						<div className="formCol">
							<div className="inputContainer inputWideContainer">
								<p className="label">27. {formLabels.ocf2}</p>
								<RadioButton value="yes" label="Yes; Please attach the OCF-2" name="ocf2" checked={values.ocf2} disabled={presentationForm}/>
								<RadioButton value="no" label="No" name="ocf2" checked={values.ocf2} disabled={presentationForm}/>
							</div>
						</div>
					</div>
					<div className="formRow">
						<div className="formCol">
							<div className="inputContainer inputWideContainer">
								<p className="label">28. {formLabels.ocf3}</p>
								<RadioButton value="yes" label="Yes; Please attach the OCF-3" name="ocf3" checked={values.ocf3} disabled={presentationForm}/>
								<RadioButton value="no" label="No" name="ocf3" checked={values.ocf3} disabled={presentationForm}/>
							</div>
						</div>
						<div className="formCol">
							<div className="inputContainer inputWideContainer">
								<p className="label">29. {formLabels.currentIRB}</p>
								<RadioButton value="yes" label="Yes" name="currentIRB" checked={values.currentIRB} disabled={presentationForm}/>
								<RadioButton value="no" label="No" name="currentIRB" checked={values.currentIRB} disabled={presentationForm}/>
							</div>
						</div>
					</div>
					<div className="formRow">
						<div className="formCol">
							<div className="inputContainer inputWideContainer">
								<p className="label">30. {formLabels.otherAssitance}</p>
								<RadioButton value="yes" label="Yes; Please explain and attach relevant documentation" name="otherAssitance" checked={values.otherAssitance} disabled={presentationForm}/>
								<RadioButton value="no" label="No" name="otherAssitance" checked={values.otherAssitance} disabled={presentationForm}/>
								{values.otherAssitance === "yes" && (
									<div className="inputContainer additionalContainer">
										<label htmlFor="otherAssitanceInfo">{formLabels.otherAssitanceInfo}</label>
										<Field type="text" name="otherAssitanceInfo" disabled={presentationForm}/>
										<ErrorMessage className="errorComponent" name="otherAssitanceInfo" component={ErrorComponent} />
									</div>
								)}
							</div>
						</div>
					</div>
					{!presentationForm && <>
					<h2>IV. Attachments (Relevant and Supporting Documentation)</h2>
					<div className="dragAndDrop">
						{uploading && <div className="uploadingFile">Uploading file</div>}
						<div {...getRootProps()}>
							<input {...getInputProps()} />
							<img src={fileUpload} alt="" />
							{isDragActive ? <p>Drop the files here ...</p> : <p className="inputFileButton">Browse files (max file size: 100mb)</p>}
							<p className="dropInfo">Or drag and drop</p>
						</div>
						{uploadedFiles.length ? <div className="formUploadedFilesContainer">
							<p>Uploaded files:</p>
							<ul className="fileList">{files}</ul>
						</div>: null}
					</div>
					<div className="formRow">
						<div className="inputContainer fullWidthInputContainer">
						<label htmlFor="additionalInfo">{formLabels.additionalInfo}</label>
							<Field component="textarea" name="additionalInfo" />
							<ErrorMessage className="errorComponent" name="additionalInfo" component={ErrorComponent} />
						</div>
					</div>
					<div className="submitFormContainer">
						<span className="requiredInfo">* required to continue</span>
						<button className="btn btn-std" type="submit" disabled={isSubmitting || !(isValid)}>
							Confirm and continue
						</button>
					</div></>}
				</Form>
			)}}
		</Formik>
	);

}
export default IrbContent;

