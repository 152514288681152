import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Authentication.css";
import logo from "../../assets/logo.png";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { noJsonFetch } from "../../utils/fetch";
import { getPass } from "../../utils/utils";
import { useParams } from "react-router-dom";
import { urls } from "../../utils/routes";
import ErrorComponent from "../../components/ErrorComponent";
import * as Yup from "yup";

const NewPassword = () => {
  const [error, setError] = useState(false);
  const [sucess, setSucess] = useState(false);
  const { code } = useParams();
  const handleSubmit = async (values, setSubmitting) => {
    const pass = await getPass(values.password);
    const data = JSON.stringify({
      "password": pass,
      "resetCode": code,
    });
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: data,
    };
    noJsonFetch("user/setNewPassword", options, true).then(response => {
      if (response.status === 200) {
        setSucess(true);
      } else {
        setError(true);
      }
      setSubmitting(false);
    }).catch(err => {
      setError(true);
      setSubmitting(false);
    });
  };

  return (
    <div className="mainAuthenticationContainer">
      <div className="authenticationBoxContainer">
        <div style={{ borderBottom: "1px solid #f4f4f5", textAlign: "center" }}>
          <img src={logo} className="authenticationLogo" alt="Great Oak logo" />
        </div>
        <div className="authenticationFormContainer">
          <Formik
            initialValues={{ password: "" }}
            validationSchema={Yup.object({
              password: Yup.string()
                .min(8, "Must be at least 8 characters")
                .required("Required"),
            })}
            onSubmit={(values, { setSubmitting }) => {
              handleSubmit(values, setSubmitting);
            }}
          >
            {({ isSubmitting, isValid, dirty }) => (
              <Form>
                <h5 className="authenticationHeader">Set New Password</h5>
                <p className="authenticationHeaderAdditional">Please enter your new password. </p>
                <div className="inputContainer">
                  <label htmlFor="password">Password</label>
                  <Field type="password" name="password" />
                  <ErrorMessage className="errorComponent" name="password" component={ErrorComponent} />
                </div>
                {error && <div className="errorDiv">Something went wrong</div>}
                {sucess && <div className="successDiv">Your password has been reset. Please login.</div>}
                <div className="text-center" style={{ marginTop: "40px" }}>
                  <button className="btn btn-std" type="submit" disabled={sucess || isSubmitting || !(isValid && dirty)}>
                    Reset
                  </button>
                </div>
                <div className="authorizationAdditionalLinks" style={{ marginTop: "30px" }}>
                  <span className="lightText">
                    If you remember password. <Link to={urls.login}>Sign In</Link>
                  </span>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default NewPassword;
