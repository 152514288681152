import React from "react";
import { useField, useFormikContext } from "formik";
import Moment from "moment";
import DatePicker from "react-datepicker";
import "./DatePickerField.css";

const DatePickerField = props => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);

  let selected;
  if (new Date(field.value) == "Invalid Date") {
    selected = null;
  } else {
    selected = new Date(field.value);
  }
  return (
    <DatePicker
      {...field}
      {...props}
      selected={selected}
      placeholderText="YYYY/MM/DD"
      dateFormat="yyyy/MM/dd"
      autoComplete="new-password"
      showYearDropdown
      onChange={val => {
        const date = Moment(new Date(val)).format("YYYY/MM/DD")
        setFieldValue(field.name, date);
      }}
    />
  );
};

export default DatePickerField;
