import React, { useCallback, useState, useContext, useRef, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import ErrorComponent from "../../components/ErrorComponent";
import FormikSelect from "../../components/FormikSelect";
import DatePickerField from "../../components/DatePickerField";
import { getDraftData } from "../../utils/fetch";
import UserContext from "../../UserContext";
import { useDropzone } from "react-dropzone";
import { saveDraft, saveDraftFile, deleteDraftFile, saveFormScreenshot } from "../../utils/fetch";
import { formLabels, prepareDraftToSave, dataURLtoFile } from "../../utils/utils";
import RadioButton from "../../components/RadioButton";
import Moment from "moment";
import * as Yup from "yup";
import fileUpload from "../../assets/fileUpload.png";
import "./Forms.css";
import { toPng } from 'dom-to-image';


const LoiContent = ({draftId, loadedFiles, oldFormData, setRedirect, presentationForm}) => {
	const [uploadedFiles, setUploadedFiles] = useState(loadedFiles ? loadedFiles : []);
	const [uploading, setUploading] = useState(false);
	const user = useContext(UserContext);
	let formValuesToSave = useRef(oldFormData);
	let lastUpdate = useRef(Moment(new Date()).format("h:mm a"));
	useEffect(() => {
		if (presentationForm) {
			return;
		}
		const timer = setInterval(() => {
			const formData = prepareDraftToSave(formValuesToSave.current);
			saveDraft(draftId, formData.clientName, formData.newValues).then(response => {
				lastUpdate.current = Moment(new Date()).format("h:mm a");
			})
		}, 20000);
		return () => { clearInterval(timer) };
	}, [draftId, presentationForm]);

  const onDrop = useCallback((acceptedFiles) => {
		const requests = acceptedFiles.map(file => saveDraftFile(draftId, file));
		Promise.all(requests).catch(err => {
			user.setRequestError(err.message.startsWith("Error: Maximum upload size exceeded;") ? "Maximum upload size exceeded" : err.message);
		}).finally(() => {
			getDraftData(draftId).then(response => {
				setUploadedFiles(response.files)
				setUploading(false);
      })
		});
	}, [draftId]);

	
	const deleteFile = (uuid) => {
		deleteDraftFile(uuid).then(() => {
			const newFiles = uploadedFiles.filter(file => file.uuid !== uuid);
			setUploadedFiles(newFiles)
		}).catch(err => {
			user.setRequestError(err.message);
		})
	}

	const onDropAccepted = () => {
		setUploading(true);
	}
	
	const onDropRejected = (file) => {
		user.setRequestError(`The file is too big.`);
	}

  let { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, onDropRejected, onDropAccepted, maxSize: 104857600 }); 
  const files = uploadedFiles.map((file) => (
    <li key={file.uuid}>
      {file.name} <span onClick={() => deleteFile(file.uuid)} className="deleteFile">+</span>
    </li>
	));
	
	return (
		<Formik
			validateOnMount={true}
			initialValues={{
				firstName: "",
				lastName: "",
				gender: "",
				address: "",
				city: "",
				province: "",
				postalCode: "",
				dob: "",
				tel: "",
				altTel: "",
				email: "",
				altFirstName: "",
				altLastName: "",
				altTelNumber: "",
				dateOfIncident: "",
				valuationDate: "",
				repNeededBy: "",
				incidentType: "",
				otherIncidentType: "",
				martialStatus: "",
				calcType: "",
				retirementAge: "",
				altAge: "",
				education: "",
				irbLoi: "",
				assistance: "",
				additionalAssistance: "",
				employmentReturn: "",
				employmentReturnDate: "",
				futureLoss: "",
				additionalInfo: "",
				formikLastUpdateDate: Moment(new Date()).format("h:mm a"),
				...oldFormData
			}}
			validationSchema={Yup.object({
				firstName: Yup.string().required("Required"),
				lastName: Yup.string().required("Required"),
				tel: Yup.string().required("Required"),
				calcType: Yup.string().required("Required"),
				email: Yup.string().email("Invalid email address"),
				dob: Yup.date().format("YYYY/MM/DD").typeError("Wrong date format"),
				dateOfIncident: Yup.date().required("Required").format("YYYY/MM/DD").typeError("Wrong date format"),
				valuationDate: Yup.date().required("Required").format("YYYY/MM/DD").typeError("Wrong date format"),
				repNeededBy: Yup.date().format("YYYY/MM/DD").typeError("Wrong date format"),
				employmentReturnDate: Yup.date().format("YYYY/MM/DD").typeError("Wrong date format"),
			})}
			onSubmit={(values, { setSubmitting }) => {
				const formData = prepareDraftToSave(values);
				toPng(document.getElementById('divToCopy'), { bgcolor: "#ffffff", quality: 0.65 }).then(file => {
					const formAsFile = dataURLtoFile(file, "Form.png")
					return saveFormScreenshot(formAsFile, draftId)
				}).then(() => {
					return saveDraft(draftId, formData.clientName, formData.newValues, true)
				})
				.then(response => {
						setRedirect(true);
				}).catch(err => {
					user.setRequestError(err.message);
				});
			}}
		>
			{({ isSubmitting, isValid, dirty, values }) => {
				formValuesToSave.current = values;
				return(
				<Form id={"divToCopy"}>
					{!presentationForm && <div className="lastUpdate">
						<span className="formUpdateBullet"></span> Auto saved in Drafts at: {lastUpdate.current}
					</div>}
					<h2>I. Claimant Information</h2>
					<div className="formRow">
						<div className="formCol">
							<div className="inputContainer">
								<label htmlFor="firstName">1. {formLabels.firstName}*</label>
								<Field type="text" name="firstName" disabled={presentationForm} />
								<ErrorMessage className="errorComponent" name="firstName" component={ErrorComponent} />
							</div>
							<div className="inputContainer">
								<label htmlFor="lastName">2. {formLabels.lastName}*</label>
								<Field type="text" name="lastName" disabled={presentationForm} />
								<ErrorMessage className="errorComponent" name="lastName" component={ErrorComponent} />
							</div>
						</div>
						<div className="formCol">
							<div className="inputContainer">
								<label htmlFor="tel">3. {formLabels.tel}*</label>
								<Field type="text" name="tel" disabled={presentationForm} />
								<ErrorMessage className="errorComponent" name="tel" component={ErrorComponent} />
							</div>
							<div className="inputContainer">
								<label htmlFor="dob">4. {formLabels.dob}</label>
								<DatePickerField name="dob" disabled={presentationForm} />
								<ErrorMessage className="errorComponent" name="dob" component={ErrorComponent} />
							</div>
						</div>
					</div>
					<div className="formRow">
						<div className="formCol">
							<div className="inputContainer inputWideContainer">
								<label htmlFor="address">5. {formLabels.address}</label>
								<Field type="text" name="address" disabled={presentationForm} />
								<ErrorMessage className="errorComponent" name="address" component={ErrorComponent} />
							</div>
						</div>
						<div className="formCol">
							<div className="inputContainer">
								<label htmlFor="city">6. {formLabels.city}</label>
								<Field type="text" name="city" disabled={presentationForm}/>
								<ErrorMessage className="errorComponent" name="city" component={ErrorComponent} />
							</div>
							<div className="inputContainer">
								<label htmlFor="province">7. {formLabels.province}</label>
								<Field type="text" name="province" disabled={presentationForm}/>
								<ErrorMessage className="errorComponent" name="province" component={ErrorComponent} />
							</div>
						</div>
					</div>
					<div className="formRow">
						<div className="formCol">
							<div className="inputContainer">
								<label htmlFor="postalCode">8. {formLabels.postalCode}</label>
								<Field type="text" name="postalCode" disabled={presentationForm}/>
								<ErrorMessage className="errorComponent" name="postalCode" component={ErrorComponent} />
							</div>
						</div>
					</div>
					<div className="formRow">
						<div className="formCol">
							<div className="inputContainer">
								<label htmlFor="altTel">9. {formLabels.altTel}</label>
								<Field type="text" name="altTel" disabled={presentationForm}/>
								<ErrorMessage className="errorComponent" name="altTel" component={ErrorComponent} />
							</div>
							<div className="inputContainer">
								<label htmlFor="email">10. {formLabels.email}</label>
								<Field type="email" name="email" disabled={presentationForm}/>
								<ErrorMessage className="errorComponent" name="email" component={ErrorComponent} />
							</div>
						</div>
						<div className="formCol">
							<div className="inputContainer">
								<label htmlFor="gender">11. {formLabels.gender}</label>
								<FormikSelect
									id="gender"
									name="gender"
									type="text"
									disabled={presentationForm}
									options={[
										{ value: "female", label: "Female" },
										{ value: "male", label: "Male" },
									]}
								/>
							</div>
							<div className="inputContainer">
								<label htmlFor="martialStatus">12. {formLabels.martialStatus}</label>
								<FormikSelect
									id="martialStatus"
									name="martialStatus"
									type="text"
									disabled={presentationForm}
									options={[
										{ value: "single", label: "Single" },
										{ value: "commonLaw", label: "Common-Law" },
										{ value: "seperated", label: "Seperated" },
										{ value: "married", label: "Married" },
										{ value: "widow", label: "Widow(er)" },
										{ value: "divorced", label: "Divorced" },
									]}
								/>
							</div>
						</div>
					</div>
					<h2>Alt. Contact Person</h2>
					<div className="formRow">
						<div className="formCol">
							<div className="inputContainer">
								<label htmlFor="altFirstName">13. {formLabels.altFirstName}</label>
								<Field type="text" name="altFirstName" disabled={presentationForm}/>
								<ErrorMessage className="errorComponent" name="altFirstName" component={ErrorComponent} />
							</div>
							<div className="inputContainer">
								<label htmlFor="altLastName">14. {formLabels.altLastName}</label>
								<Field type="text" name="altLastName" disabled={presentationForm}/>
								<ErrorMessage className="errorComponent" name="altLastName" component={ErrorComponent} />
							</div>
							</div>                
						<div className="formCol">
							<div className="inputContainer">
								<label htmlFor="altTelNumber">15. {formLabels.altTelNumber}</label>
								<Field type="text" name="altTelNumber" disabled={presentationForm}/>
								<ErrorMessage className="errorComponent" name="altTelNumber" component={ErrorComponent} />
							</div>
						</div>
					</div>
					<h2>II. Event Information</h2>
					<div className="formRow">
						<div className="formCol">
							<div className="inputContainer inputWideContainer">
								<label htmlFor="dateOfIncident">16. {formLabels.dateOfIncident}*</label>
								<DatePickerField name="dateOfIncident" disabled={presentationForm}/>
								<ErrorMessage className="errorComponent" name="dateOfIncident" component={ErrorComponent} />
							</div>
						</div>
						<div className="formCol">
							<div className="inputContainer inputWideContainer">
								<label htmlFor="valuationDate">17. {formLabels.valuationDate}*</label>
								<DatePickerField name="valuationDate" disabled={presentationForm}/>
								<ErrorMessage className="errorComponent" name="valuationDate" component={ErrorComponent} />
							</div>
						</div>
					</div>
					<div className="formRow">
						<div className="formCol">
							<div className="inputContainer inputWideContainer">
								<label htmlFor="repNeededBy">18. {formLabels.repNeededBy}</label>
								<DatePickerField name="repNeededBy" disabled={presentationForm}/>
								<ErrorMessage className="errorComponent" name="repNeededBy" component={ErrorComponent} />
							</div>
						</div>
						<div className="formCol">
							<div className="inputContainer inputWideContainer">
								<label htmlFor="incidentType">19. {formLabels.incidentType}</label>
								<FormikSelect
									id="incidentType"
									name="incidentType"
									type="text"
									disabled={presentationForm}
									options={[
										{ value: "motor", label: "Motor Vehicle Accident (MVA)" },
										{ value: "other", label: "Other" },
									]}
								/>
								{values.incidentType.value === "other" && (
									<div className="inputContainer inputWideContainer additionalContainer additionalContainerNoLm" style={{marginTop: "15px"}}>
										<label htmlFor="otherIncidentType">{formLabels.otherIncidentType}</label>
										<Field type="text" name="otherIncidentType" disabled={presentationForm}/>
										<ErrorMessage className="errorComponent" name="otherIncidentType" component={ErrorComponent} />
									</div>
								)}
							</div>
						</div>
					</div>
					<h2>III. Calculation</h2>
					<div className="formRow">
						<div className="formCol">
							<div className="inputContainer">
								<p className="label">20. {formLabels.calcType}*</p>
								<RadioButton value="past" label="Past (pre-trial only)" name="calcType" checked={values.calcType} disabled={presentationForm}/>
								<RadioButton
									value="future"
									label="Future (post-trial only)"
									name="calcType"
									checked={values.calcType}
									disabled={presentationForm}
								/>
								<RadioButton value="both" label="Both Past and Future" name="calcType" checked={values.calcType} disabled={presentationForm}/>
							</div>
						</div>
					</div>
					<h2>IV. Relevant Information</h2>
					<div className="formRow">
						<div className="formCol">
							<div className="inputContainer inputWideContainer">
								<p className="label">21. {formLabels.retirementAge}</p>
								<RadioButton
									value="eligibility"
									label="As per CPP Elgibility (i.e. 65 years of age)"
									name="retirementAge"
									checked={values.retirementAge}
									disabled={presentationForm}
								/>
								<RadioButton
									value="alternative"
									label="Alternative Age of Retirement (Please suggest age and explain)"
									name="retirementAge"
									checked={values.retirementAge}
									disabled={presentationForm}
								/>
								{values.retirementAge === "alternative" && (
									<div className="inputContainer additionalContainer">
										<label htmlFor="dateOfIncident">{formLabels.altAge}</label>
										<Field type="text" name="altAge" disabled={presentationForm}/>
										<ErrorMessage className="errorComponent" name="altAge" component={ErrorComponent} />
									</div>
								)}
							</div>
						</div>
						<div className="formCol">
							<div className="inputContainer inputWideContainer">
								<label htmlFor="education">22. {formLabels.education}</label>
								<Field type="text" name="education" disabled={presentationForm}/>
								<ErrorMessage className="errorComponent" name="education" component={ErrorComponent} />
							</div>
						</div>
					</div>
					<div className="formRow">
						<div className="formCol">
							<div className="inputContainer inputWideContainer">
								<p className="label">23. {formLabels.irbLoi}</p>
								<RadioButton
									value="yes"
									label="Yes; Please attach IRB payment(s) received breakdown, if possible"
									name="irbLoi"
									checked={values.irbLoi}
									disabled={presentationForm}
								/>
								<RadioButton value="no" label="No" name="irbLoi" checked={values.irbLoi} disabled={presentationForm}/>
							</div>
						</div>
						<div className="formCol">
							<div className="inputContainer inputWideContainer">
								<p className="label">
									24. {formLabels.assistance}
								</p>
								<RadioButton
									value="yes"
									label="Yes; Please explain and attach relevant documentation"
									name="assistance"
									checked={values.assistance}
									disabled={presentationForm}
								/>
								<RadioButton value="no" label="No" name="assistance" checked={values.assistance} disabled={presentationForm}/>
								{values.assistance === "yes" && (
									<div className="inputContainer additionalContainer">
										<label htmlFor="dateOfIncident">{formLabels.additionalAssistance}</label>
										<Field type="text" name="additionalAssistance" disabled={presentationForm}/>
										<ErrorMessage className="errorComponent" name="additionalAssistance" component={ErrorComponent} />
									</div>
								)}
							</div>
						</div>
					</div>
					<div className="formRow">
						<div className="formCol">
							<div className="inputContainer inputWideContainer">
								<p className="label">25. {formLabels.employmentReturn}</p>
								<RadioButton
									value="yes"
									label="Returned to Work in Some Capacity"
									name="employmentReturn"
									checked={values.employmentReturn}
									disabled={presentationForm}
								/>
								<RadioButton
									value="no"
									label="Not Returned to Work"
									name="employmentReturn"
									checked={values.employmentReturn}
									disabled={presentationForm}
								/>
								{values.employmentReturn === "yes" && (
									<div className="inputContainer additionalContainer">
										<label htmlFor="employmentReturnDate">{formLabels.employmentReturnDate}</label>
										<DatePickerField name="employmentReturnDate" disabled={presentationForm}/>
										<ErrorMessage className="errorComponent" name="employmentReturnDate" component={ErrorComponent} />
									</div>
								)}
							</div>
						</div>
					</div>
					<h2>V. {formLabels.futureLoss}</h2>
					<div className="formRow">
						<div className="inputContainer fullWidthInputContainer" style={{marginTop: 0}}>
							<RadioButton
								value="1"
								label="<b>Option 1 (Default)</b>. Based on file documentation, communication with client and professional judgement of expert."
								name="futureLoss"
								checked={values.futureLoss}
								disabled={presentationForm}
							/>
							<RadioButton
								value="2"
								label="<b>Option 2. </b>Custom future loss scenario as provided by referral source.  Please attach relevant documentation and accompanying instructions for expert review."
								name="futureLoss"
								checked={values.futureLoss}
								disabled={presentationForm}
							/>
						</div>
					</div>
					{!presentationForm && <>
					<h2>V. Attachments (Relevant and Supporting Documentation)</h2>
					<div className="dragAndDrop">
						{uploading && <div className="uploadingFile">Uploading file</div>}
						<div {...getRootProps()}>
							<input {...getInputProps()} />
							<img src={fileUpload} alt="" />
							{isDragActive ? <p>Drop the files here ...</p> : <p className="inputFileButton">Browse files (max file size: 100mb)</p>}
							<p className="dropInfo">Or drag and drop</p>
						</div>
						{uploadedFiles.length ? <div className="formUploadedFilesContainer">
							<p>Uploaded files:</p>
							<ul className="fileList">{files}</ul>
						</div>: null}
					</div>
					<div className="formRow">
						<div className="inputContainer fullWidthInputContainer">
						<label htmlFor="additionalInfo">{formLabels.additionalInfo}</label>
							<Field component="textarea" name="additionalInfo" />
							<ErrorMessage className="errorComponent" name="additionalInfo" component={ErrorComponent} />
						</div>
					</div>
					<div className="submitFormContainer">
						<span className="requiredInfo">* required to continue</span>
						<button className="btn btn-std" type="submit" disabled={isSubmitting || !(isValid)}>
							Confirm and continue
						</button> 
					</div></>}
				</Form>
			)}}
		</Formik>
	);

}
export default LoiContent;

