import React, { useContext } from "react";
import { Redirect } from "react-router-dom";
import { urls } from "../../utils/routes";
import UserContext from "../../UserContext";

const Logout = () => {
  const user = useContext(UserContext);
  user.updateIsAuthenticated(false);
  localStorage.setItem("oak_token", "");
  localStorage.setItem("oak_ref", "");
  if (user.state.isAuthenticated) {
    return <div></div>;
  }
  return (
    <Redirect
      to={{
        pathname: urls.login,
      }}
    />
  );
};

export default Logout;
