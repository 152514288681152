import React from "react";
import "./ErrorComponent.css";
import exclamation from "../../assets/exclamation.png";

const ErrorComponent = props => {
  return (
    <div className="errorComponent">
      <img className="exclamation" src={exclamation} alt="" />
      {props.children}
    </div>
  );
};

export default ErrorComponent;
