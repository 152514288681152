import React, { useEffect, useState }  from "react";
import "./Authentication.css";
import logo from "../../assets/logo.png";
import {noJsonFetch} from "../../utils/fetch";
import { Link, useParams } from "react-router-dom";
import { urls } from "../../utils/routes";

const AccountConfirmed = () => {  
  const { code } = useParams();
  const [response, setResponse] = useState(null);
  useEffect(() => {
    const options = { method: "GET" };
    noJsonFetch(`user/confirmAccount?hash=${code}`, options, true).then(response => {
      if (response.status === 200) {
				setResponse(true);
			} else {
				setResponse(false);
			}
    }).catch(err => {
      setResponse(false);
    });
  }, []);

  if (response === null) {
    return null;
  }

  return (
    <div className="mainAuthenticationContainer">
      <div className="authenticationBoxContainer">
        <div style={{ borderBottom: "1px solid #f4f4f5", textAlign: "center" }}>
          <img src={logo} className="authenticationLogo" alt="Great Oak logo" />
        </div>
        {response && <div className="authenticationFormContainer">
					<div style={{margin: "0 0 30px", textAlign: "center"}} className="successDiv">You have successfully confirmed your account!</div>
					<div className="authorizationAdditionalLinks">
						<span className="lightText">
							<Link to={urls.login}>Click here to sign in to your account</Link>
						</span>
					</div>
        </div>}
        {!response && <div className="authenticationFormContainer">
					<div style={{margin: "0 0 30px", textAlign: "center"}} className="errorDiv">Something went wrong. Please contact the administrator.</div>
        </div>}
      </div>
    </div>
  );
}

export default AccountConfirmed;
