import React, { useCallback, useState, useContext, useRef, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import ErrorComponent from "../../components/ErrorComponent";
import FormikSelect from "../../components/FormikSelect";
import DatePickerField from "../../components/DatePickerField";
import UserContext from "../../UserContext";
import { getDraftData } from "../../utils/fetch";
import { useDropzone } from "react-dropzone";
import { saveDraft, saveDraftFile, deleteDraftFile, saveFormScreenshot } from "../../utils/fetch";
import { formLabels, prepareDraftToSave, dataURLtoFile } from "../../utils/utils";
import RadioButton from "../../components/RadioButton";
import Moment from "moment";
import * as Yup from "yup";
import fileUpload from "../../assets/fileUpload.png";
import "./Forms.css";
import { toPng } from 'dom-to-image';

const VocContent = ({draftId, loadedFiles, oldFormData, setRedirect, presentationForm}) => {
	const [uploadedFiles, setUploadedFiles] = useState(loadedFiles ? loadedFiles : []);
	const [uploading, setUploading] = useState(false);
	const user = useContext(UserContext);
	let formValuesToSave = useRef(oldFormData);
	let lastUpdate = useRef(Moment(new Date()).format("h:mm a"));
	useEffect(() => {
		if (presentationForm) {
			return;
		}
		const timer = setInterval(() => {
			const formData = prepareDraftToSave(formValuesToSave.current);
			saveDraft(draftId, formData.clientName, formData.newValues).then(response => {
				lastUpdate.current = Moment(new Date()).format("h:mm a");
			})
		}, 20000);
		return () => { clearInterval(timer) };
	}, [draftId, presentationForm]);

  const onDrop = useCallback((acceptedFiles) => {
		const requests = acceptedFiles.map(file => saveDraftFile(draftId, file));
		Promise.all(requests).catch(err => {
			user.setRequestError(err.message.startsWith("Error: Maximum upload size exceeded;") ? "Maximum upload size exceeded" : err.message);
		}).finally(() => {
			getDraftData(draftId).then(response => {
				setUploadedFiles(response.files);
				setUploading(false);
      })
		});
	}, [draftId]);
		
	const deleteFile = (uuid) => {
		deleteDraftFile(uuid).then(() => {
			const newFiles = uploadedFiles.filter(file => file.uuid !== uuid);
			setUploadedFiles(newFiles)
		}).catch(err => {
			user.setRequestError(err.message);
		})
	} 

	const onDropAccepted = () => {
		setUploading(true);
	}
	
	const onDropRejected = (file) => {
		user.setRequestError(`The file is too big.`);
	}

  let { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, onDropRejected, onDropAccepted, maxSize: 104857600 }); 
  const files = uploadedFiles.map((file) => (
    <li key={file.uuid}>
      {file.name} <span onClick={() => deleteFile(file.uuid)} className="deleteFile">+</span>
    </li>
	));
	
	return (
		<Formik
		validateOnMount={true}
		initialValues={{
			firstName: "",
			lastName: "",
			gender: "",
			address: "",
			city: "",
			province: "",
			postalCode: "",
			tel: "",
			altTel: "",
			email: "",
			altFirstName: "",
			altLastName: "",
			altTelNumber: "",
			dateOfIncident: "",
			lang: "",
			otherLang: "",
			dob: "",
			pov: "",
			repNeededBy: "",
			additionalInfo: "",
			insuranceCompany: "",
			adjusterName: "",
			insuranceTel: "",
			insuranceFax: "",
			insuranceEmail: "",
			claimNumber: "",
			policyNumber: "",
			policyHolder: "",
			...oldFormData
		}}
		validationSchema={Yup.object({
			firstName: Yup.string().required("Required"),
			lastName: Yup.string().required("Required"),
			tel: Yup.string().required("Required"),
			pov: Yup.string().required("Required"),
			dateOfIncident: Yup.date().format("YYYY/MM/DD").typeError("Wrong date format"),
			email: Yup.string().email("Invalid email address"),
			insuranceEmail: Yup.string().email("Invalid email address"),
			dob: Yup.date().format("YYYY/MM/DD").typeError("Wrong date format"),
		})}
		onSubmit={(values, { setSubmitting }) => {
				const formData = prepareDraftToSave(values);
				toPng(document.getElementById('divToCopy'), { bgcolor: "#ffffff", quality: 0.65 }).then(file => {
					const formAsFile = dataURLtoFile(file, "Form.png")
					return saveFormScreenshot(formAsFile, draftId)
				}).then(() => {
					return saveDraft(draftId, formData.clientName, formData.newValues, true)
				})
				.then(response => {
						setRedirect(true);
				}).catch(err => {
					user.setRequestError(err.message);
				});
		}} 
	>
		{({ isSubmitting, isValid, dirty, values }) => {
			return(
			<Form id={"divToCopy"}>
				{!presentationForm && <div className="lastUpdate">
						<span className="formUpdateBullet"></span> Auto saved in Drafts at: {lastUpdate.current}
				</div>}
				<h2>I. Client Information</h2>
				<div className="formRow">
					<div className="formCol">
						<div className="inputContainer">
							<label htmlFor="firstName">1. {formLabels.firstName}*</label>
							<Field type="text" name="firstName" disabled={presentationForm}/>
							<ErrorMessage className="errorComponent" name="firstName" component={ErrorComponent} />
						</div>
						<div className="inputContainer">
							<label htmlFor="lastName">2. {formLabels.lastName}*</label>
							<Field type="text" name="lastName" disabled={presentationForm}/>
							<ErrorMessage className="errorComponent" name="lastName" component={ErrorComponent} />
						</div>
					</div>
					<div className="formCol">
						<div className="inputContainer">
							<label htmlFor="gender">3. {formLabels.gender}</label>
							<FormikSelect
								id="gender"
								name="gender"
								type="text"
								disabled={presentationForm}
								options={[
									{ value: "female", label: "Female" },
									{ value: "male", label: "Male" },
								]}
							/>
						</div>
						<div className="inputContainer">
							<label htmlFor="dob">4. {formLabels.dob}</label>
							<DatePickerField name="dob" disabled={presentationForm}/>
							<ErrorMessage className="errorComponent" name="dob" component={ErrorComponent} />
						</div>
					</div>
				</div>
				<div className="formRow">
					<div className="formCol">
						<div className="inputContainer">
							<label htmlFor="tel">5. {formLabels.tel}*</label>
							<Field type="text" name="tel" disabled={presentationForm}/>
							<ErrorMessage className="errorComponent" name="tel" component={ErrorComponent} />
						</div>
						<div className="inputContainer">
							<label htmlFor="altTel">6. {formLabels.altTel}</label>
							<Field type="text" name="altTel" disabled={presentationForm}/>
							<ErrorMessage className="errorComponent" name="altTel" component={ErrorComponent} />
						</div>
					</div>
					<div className="formCol">
						<div className="inputContainer">
							<label htmlFor="email">7. {formLabels.email}</label>
							<Field type="email" name="email" disabled={presentationForm}/>
							<ErrorMessage className="errorComponent" name="email" component={ErrorComponent} />
						</div>
						<div className="inputContainer">
							<label htmlFor="lang">8. {formLabels.lang}</label>
							<FormikSelect
								id="lang"
								name="lang"
								disabled={presentationForm}
								options={[
									{ value: "english", label: "English" },
									{ value: "other", label: "Other Language" },
								]}
							/>
							{values.lang.value === "other" && (
								<div className="inputContainer additionalContainer additionalContainerNoLm" style={{marginTop: "15px"}}>
									<label htmlFor="otherLang">{formLabels.otherLang}</label>
									<Field type="text" name="otherLang" disabled={presentationForm}/>
									<ErrorMessage className="errorComponent" name="otherLang" component={ErrorComponent} />
								</div>
							)}
						</div>
					</div>
				</div>
				<div className="formRow">
					<div className="formCol">
						<div className="inputContainer inputWideContainer">
							<label htmlFor="address">9. {formLabels.address}</label>
							<Field type="text" name="address" disabled={presentationForm}/>
							<ErrorMessage className="errorComponent" name="address" component={ErrorComponent} />
						</div>
					</div>
					<div className="formCol">
						<div className="inputContainer">
							<label htmlFor="city">10. {formLabels.city}</label>
							<Field type="text" name="city" disabled={presentationForm}/>
							<ErrorMessage className="errorComponent" name="city" component={ErrorComponent} />
						</div>
						<div className="inputContainer">
							<label htmlFor="province">11. {formLabels.province}</label>
							<Field type="text" name="province" disabled={presentationForm}/>
							<ErrorMessage className="errorComponent" name="province" component={ErrorComponent} />
						</div>
					</div>
				</div>
				<div className="formRow">
					<div className="formCol">
						<div className="inputContainer">
							<label htmlFor="postalCode">12. {formLabels.postalCode}</label>
							<Field type="text" name="postalCode" disabled={presentationForm}/>
							<ErrorMessage className="errorComponent" name="postalCode" component={ErrorComponent} />
						</div>
					</div>
				</div>
				<div className="formRow">
					<div className="formCol">
						<div className="inputContainer">
							<label htmlFor="dateOfIncident">13. {formLabels.dateOfIncident}</label>
							<DatePickerField name="dateOfIncident" disabled={presentationForm}/>
							<ErrorMessage className="errorComponent" name="dateOfIncident" component={ErrorComponent} />
						</div>
					</div>
				</div>
				<h2>Alt. Contact Person</h2>
				<div className="formRow">
					<div className="formCol">
						<div className="inputContainer">
							<label htmlFor="altFirstName">14. {formLabels.altFirstName}</label>
							<Field type="text" name="altFirstName" disabled={presentationForm}/>
							<ErrorMessage className="errorComponent" name="altFirstName" component={ErrorComponent} />
						</div>
						<div className="inputContainer">
							<label htmlFor="altLastName">15. {formLabels.altLastName}</label>
							<Field type="text" name="altLastName" disabled={presentationForm}/>
							<ErrorMessage className="errorComponent" name="altLastName" component={ErrorComponent} />
						</div>
						</div>                
					<div className="formCol">
						<div className="inputContainer">
							<label htmlFor="altTelNumber">16. {formLabels.altTelNumber}</label>
							<Field type="text" name="altTelNumber" disabled={presentationForm}/>
							<ErrorMessage className="errorComponent" name="altTelNumber" component={ErrorComponent} />
						</div>
					</div>
				</div>
				<h2>II. Service Type</h2>
				<div className="formRow">
					<div className="formCol">
						<div className="inputContainer">
							<p className="label">17. {formLabels.pov}*</p>
							<RadioButton value="mva" label="Tort Civil Litigation - MVA" name="pov" checked={values.pov} disabled={presentationForm}/>
							<RadioButton value="ltda" label="Long Term Disability Assessment" name="pov" checked={values.pov} disabled={presentationForm}/>
							<RadioButton value="post104" label="Post 104 - Disability Test No" name="pov" checked={values.pov} disabled={presentationForm}/>
						</div>
					</div>
				</div>
				<div className="formRow">
					<div className="formCol">
						<div className="inputContainer inputWideContainer">
							<label htmlFor="repNeededBy">18. {formLabels.repNeededBy}</label>
							<DatePickerField name="repNeededBy" disabled={presentationForm}/>
							<ErrorMessage className="errorComponent" name="repNeededBy" component={ErrorComponent} />
						</div>
					</div>
				</div>
				<h2>III. Insurance Information (If Applicable)</h2>
				<div className="formRow">
					<div className="formCol">
						<div className="inputContainer">
							<label htmlFor="insuranceCompany">19. {formLabels.insuranceCompany}</label>
							<Field type="text" name="insuranceCompany" disabled={presentationForm}/>
							<ErrorMessage className="errorComponent" name="insuranceCompany" component={ErrorComponent} />
						</div>
						<div className="inputContainer">
							<label htmlFor="adjusterName">20. {formLabels.adjusterName}</label>
							<Field type="text" name="adjusterName" disabled={presentationForm}/>
							<ErrorMessage className="errorComponent" name="adjusterName" component={ErrorComponent} />
						</div>
					</div>
					<div className="formCol">
						<div className="inputContainer">
							<label htmlFor="insuranceTel">21. {formLabels.insuranceTel}</label>
							<Field type="text" name="insuranceTel" disabled={presentationForm}/>
							<ErrorMessage className="errorComponent" name="insuranceTel" component={ErrorComponent} />
						</div>
						<div className="inputContainer">
							<label htmlFor="insuranceFax">22. {formLabels.insuranceFax}</label>
							<Field type="text" name="insuranceFax" disabled={presentationForm}/>
							<ErrorMessage className="errorComponent" name="insuranceFax" component={ErrorComponent} />
						</div>
					</div>
				</div>
				<div className="formRow">
					<div className="formCol">
						<div className="inputContainer inputWideContainer">
							<label htmlFor="insuranceEmail">23. {formLabels.insuranceEmail}</label>
							<Field type="email" name="insuranceEmail" disabled={presentationForm}/>
							<ErrorMessage className="errorComponent" name="insuranceEmail" component={ErrorComponent} />
						</div>
					</div>
					<div className="formCol">
						<div className="inputContainer inputWideContainer">
							<label htmlFor="claimNumber">24. {formLabels.claimNumber}</label>
							<Field type="text" name="claimNumber" disabled={presentationForm}/>
							<ErrorMessage className="errorComponent" name="claimNumber" component={ErrorComponent} />
						</div>
					</div>
				</div>
				<div className="formRow">
					<div className="formCol">
						<div className="inputContainer inputWideContainer">
							<label htmlFor="policyNumber">25. {formLabels.policyNumber}</label>
							<Field type="text" name="policyNumber" disabled={presentationForm}/>
							<ErrorMessage className="errorComponent" name="policyNumber" component={ErrorComponent} />
						</div>
					</div>
					<div className="formCol">
						<div className="inputContainer inputWideContainer">
							<label htmlFor="policyHolder">26. {formLabels.policyHolder}</label>
							<Field type="text" name="policyHolder" disabled={presentationForm}/>
							<ErrorMessage className="errorComponent" name="policyHolder" component={ErrorComponent} />
						</div>
					</div>
				</div>
				{!presentationForm && <>
				<h2>IV. Attachments (Relevant and Supporting Documentation)</h2>
				<div className="dragAndDrop">
					{uploading && <div className="uploadingFile">Uploading file</div>}
					<div {...getRootProps()}>
						<input {...getInputProps()} />
						<img src={fileUpload} alt="" />
						{isDragActive ? <p>Drop the files here ...</p> : <p className="inputFileButton">Browse files (max file size: 100mb)</p>}
						<p className="dropInfo">Or drag and drop</p>
					</div>
					{uploadedFiles.length ? <div className="formUploadedFilesContainer">
						<p>Uploaded files:</p>
						<ul className="fileList">{files}</ul>
					</div>: null}
				</div>
				<div className="formRow">
					<div className="inputContainer fullWidthInputContainer">
					<label htmlFor="additionalInfo">{formLabels.additionalInfo}</label>
						<Field component="textarea" name="additionalInfo" />
						<ErrorMessage className="errorComponent" name="additionalInfo" component={ErrorComponent} />
					</div>
				</div>
				<div className="submitFormContainer">
					<span className="requiredInfo">* required to continue</span>
					<button className="btn btn-std" type="submit" disabled={isSubmitting || !(isValid)}>
						Confirm and continue
					</button>
				</div></>}
			</Form>
		)}}
	</Formik>	
	);
}

export default VocContent;

