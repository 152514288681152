import React, { useState, useEffect, useContext, useRef } from "react";
import Checkbox from "../../components/Checkbox";
import Modal from "../../components/Modal";
import UserContext from "../../UserContext";
import fetchBasic, { getStdOptions } from "../../utils/fetch";
import { numberWithCommas, dataURLtoFile } from "../../utils/utils";
import { urls } from "../../utils/routes";
import { useParams, Redirect } from "react-router-dom";
import "./NewReferral.css";
import { toPng } from 'dom-to-image';

const ServiceTermSheet = () => {
  const [agreementCheckbox, setAgreementCheckbox] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [sending, setSending] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [data, setData] = useState([]);
  const [allCheckboxesChecked, setAllCheckboxesChecked] = useState([]);
  const { employeeId, serviceId } = useParams();
  const [redirect, setRedirect] = useState("");
  const [sumValue, setSumValue] = useState(0)
  const user = useContext(UserContext);
  let stsFile = useRef("");

  const addCheckboxCheckedValue = array => {
    return array.map(item => {
      return { ...item, checked: false };
    });
  };

  const updateElementsChecked = (value, index) => {
    const newElements = data.elements.map((element, i) => {
      if (index === i) {
        return { ...element, checked: value === "false" ? true : false };
      } else return element;
    });
    setData({ ...data, elements: newElements });
  };

  const updateExtraChecked = (value, index) => {
    const newExtra = data.extras.map((element, i) => {
      if (index === i) {
        return { ...element, checked: value === "false" ? true : false };
      } else return element;
    });
    setData({ ...data, extras: newExtra });
  };

  useEffect(() => {
    const options = getStdOptions();
    fetchBasic(`/service/termsheet?employeeId=${employeeId}&serviceId=${serviceId}`, options, true)
      .then(response => {
        if (response.tokenRefreshed) {
          return fetchBasic(
            `/service/termsheet?employeeId=${employeeId}&serviceId=${serviceId}`,
            getStdOptions(),
            false,
          )
            .then(response => {
              setDataLoaded(true);
              response.elements = addCheckboxCheckedValue(response.elements);
              response.extras = addCheckboxCheckedValue(response.extras);
              response.elements.sort((a,b) => a.index - b.index);
              response.extras.sort((a,b) => a.index - b.index);              
              setData(response);
            })
            .catch(err => {
              return err;
            });
        } else {
          setDataLoaded(true);
          response.elements = addCheckboxCheckedValue(response.elements);
          response.extras = addCheckboxCheckedValue(response.extras);
          response.elements.sort((a,b) => a.index - b.index);
          response.extras.sort((a,b) => a.index - b.index);
          setData(response);
        }
      })
      .catch(err => {
        user.setRequestError(err.message);
        return err;
      });
  }, [employeeId, serviceId]);
  useEffect(() => {
    
    const calculateTotal = () => {
      if (data.elements) {
        let sum = 0;
        data.elements.concat(data.extras).forEach(element => {
          if(element.checked && element.type === "COST") {
            sum += Number(element.value);
          }
        });
        setSumValue(sum)
      }
    }
    
    const checkIfAllChecked = () => {
      if (data.elements) {
        let allTrue = true;
        data.elements.forEach(element => {
          if (!element.checked) {
            allTrue = false;
          }
        });
        setAllCheckboxesChecked(allTrue);
        return;
      }
      setAllCheckboxesChecked(false);
      return;
    };
    calculateTotal();
    checkIfAllChecked();
  }, [data]);

  const handleContinue = () => {
    if (!agreementCheckbox) {
      setModalVisible(true);
      return;
    }
    setSending(true);
    toPng(document.getElementById('divToCopy'), { bgcolor: "#ffffff", quality: 0.65 }).then(file => {
      stsFile.current = file;
      setRedirect(`${urls.formContainer}/${data.formCode.toLowerCase()}`)
    }).catch(() => {
      setSending(false);
    })
  };

  const handleAgreementCheckbox = (value) => {
    setModalVisible(true);
  }

  const handleModalAgree = () => {
    setAgreementCheckbox(true);
    setModalVisible(false);
  };

  const closeModal = () => {
    setModalVisible(false);
    setAgreementCheckbox(false);
  }

  if (redirect) {
    const stsAsFile = dataURLtoFile(stsFile.current, "STS.png")
    return (
      <Redirect
      to={{
        pathname: redirect,
        state: { employeeId, serviceId, stsFile: stsAsFile },
      }}
    />
    )
  }

  let subtotal = sumValue / 100;
  let subtotalDiscount = sumValue / 100 * (1 - data.discount / 100);
  return (
    <>
      <a target="_blank" href="https://api.greatoakvfa.com/banner" rel="noopener noreferrer"><img className="adBaner" src="https://api.greatoakvfa.com/static/banner.png" alt=""/></a> 
      <div className="mainReferralContainer serviceTermSheetContainer" id={"divToCopy"}>
        <Modal visible={modalVisible} close={closeModal} agree={handleModalAgree} />
        <p>
          New referral{" "}
          {data.serviceTitle && (
            <span>
              / {data.serviceTitle} / {data.employeeTitle}
            </span>
          )}
        </p>
        <h1>/ Service Term Sheet</h1>
        <section>
          {!data.elements && dataLoaded && <h4>No term sheets</h4>}
          {data.elements &&
            data.elements.map((item, index) => {
              return (
                <div className="serviceTermBox" key={index}>
                  <div className="serviceTermUpper">
                    <div className="serviceTermTitle">
                      {index + 1}. {item.title}
                    </div>
                    {item.type === "COST" && (
                      <div className="serviceTermAmount">
                        {item.valueDescription && <span className="valueDescription">{item.valueDescription}: </span>}$
                        {numberWithCommas(item.value / 100)}
                      </div>
                    )}
                    {item.type === "TIMEFRAME" && (
                      <div className="serviceTermAmount">
                        <span className="valueDescription">Timeframe:</span> {numberWithCommas(item.value / 7)} Weeks
                      </div>
                    )}
                    <div className="serviceTermAgreement">
                      Agreed{" "}
                      <Checkbox
                        name="test"
                        value={data.elements[index].checked}
                        onChange={e => updateElementsChecked(e.target.value, index)}
                      />
                    </div>
                  </div>
                  <div className="serviceTermLower">
                    <ul>
                      <li
                        dangerouslySetInnerHTML={{
                          __html: item.description,
                        }}
                      ></li>
                    </ul>
                  </div>
                </div>
              );
            })}
        </section>
        {data.extras && <h2 className="serviceTermHeader">Extras</h2>}
        <section>
          {data.extras &&
            data.extras.map((item, index) => {
              return (
                <div className="serviceTermBox" key={index}>
                  <div className="serviceTermUpper">
                    <div className="serviceTermTitle">
                      {data.elements.length + index + 1}. {item.title}
                    </div>
                    {item.type === "COST" && (
                      <div className="serviceTermAmount">
                        {item.valueDescription && <span className="valueDescription">{item.valueDescription}: </span>}$
                        {numberWithCommas(item.value / 100)}
                      </div>
                    )}
                    {item.type === "TIMEFRAME" && (
                      <div className="serviceTermAmount">
                        <span className="valueDescription">Timeframe:</span> {numberWithCommas(item.value / 7)} Weeks
                      </div>
                    )}
                    <div className="serviceTermAgreement">
                      Agreed{" "}
                      <Checkbox
                        name="test"
                        value={data.extras[index].checked}
                        onChange={e => updateExtraChecked(e.target.value, index)}
                      />
                    </div>{" "}
                  </div>
                  <div className="serviceTermLower">
                    <ul>
                      <li
                        dangerouslySetInnerHTML={{
                          __html: item.description,
                        }}
                      ></li>
                    </ul>
                  </div>
                </div>
              );
            })}
        </section>
        {data.payment && (
          <h2 className="serviceTermHeader" style={{ marginTop: "80px" }}>
            Payment Terms
          </h2>
        )}
        <section>
          <div className="serviceTermBox">
            <div className="serviceTermUpper paymentTermUpper">
              <div className="paymentTermTitle">Regular Payment Terms</div>
              <div className="paymentTermAmount">
                <div>
                  <span className="paymentTermAmountLabel">Subtotal:</span>
                  <span className="paymentTermAmountValue">$ {numberWithCommas(subtotal)}</span>
                </div>
                <div>
                  <span className="paymentTermAmountLabel">HST Tax:</span>
                  <span className="paymentTermAmountValue">$ {numberWithCommas(subtotal * (data.tax / 100))}</span>
                </div>
              </div>
            </div>
            <div className="serviceTermLower">
              <ul className="serviceTermList">
                <li>Guaranteed to be interest free for a period of 24 months from date of invoice. Interest shall apply after expiry of interest free period.</li>
              </ul>
              <div className="paymentTermTotal">
                <div>
                  <span className="paymentTermAmountLabel">Total:</span>
                  <span className="paymentTermAmountValue">$ {numberWithCommas(subtotal + subtotal * (data.tax / 100))}</span>
                </div>
              </div>
            </div>
          </div>
          {Boolean(data.discount) && 
          <div className="serviceTermBox">
            <div className="serviceTermUpper paymentTermUpper">
              <div className="paymentTermTitle">Early Payment Option</div>
              <div className="paymentTermAmount">
                <div>
                  <span className="paymentTermAmountLabel">Subtotal:</span>
                  <span className="paymentTermAmountValue">$ {numberWithCommas(subtotalDiscount)}</span>
                </div>
                <div>
                  <span className="paymentTermAmountLabel">HST Tax:</span>
                  <span className="paymentTermAmountValue">$ {numberWithCommas(subtotalDiscount * (data.tax / 100))}</span>
                </div>
              </div>
            </div>
            <div className="serviceTermLower">
              <ul className="serviceTermList">
                <li>Payment must be made within 45 days of invoice date or regular payment terms apply.</li>
              </ul>
              <div className="paymentTermTotal">
                <div>
                  <span className="paymentTermAmountLabel">Total:</span>
                  <span className="paymentTermAmountValue">$ {numberWithCommas(subtotalDiscount + subtotalDiscount * (data.tax / 100))}</span>
                </div>
              </div>
            </div>
          </div>}
        </section>
        <div className="serviceTermBox serviceTermSumUpBox">
          <div className="serviceTermsAgreementContainer">
            Agree to Terms & Conditions
            <Checkbox name="test" value={agreementCheckbox} onChange={e => handleAgreementCheckbox(e.target.checked)} />
          </div>
          <div>
            <button
              className="btn btn-std"
              style={{ padding: "11px", minWidth: "140px", margin: "4px 0" }}
              disabled={!allCheckboxesChecked || !agreementCheckbox || sending}
              onClick={handleContinue}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceTermSheet;
